import {NgModule} from '@angular/core';
import {CommonDoaToaModule} from "../../../../../doatoa-common/common-doatoa.module";

import {SignOutLandingComponent} from "./sign-out-landing/sign-out-landing.component";
import {OauthInitComponent} from "./oauth-init/oauth-init.component";
import {OauthLandingComponent} from "./oauth-landing/oauth-landing.component";
import {PaymentsUpdateLandingComponent} from "./payments-update/payments-update-landing.component";
import {PaymentsInitComponent} from "./payments-init/payments-init.component";

@NgModule({
    imports: [
        CommonDoaToaModule
    ],
    declarations: [SignOutLandingComponent, OauthInitComponent, OauthLandingComponent, PaymentsUpdateLandingComponent, PaymentsInitComponent]
})
export class LandingModule { }