<div class="container card p-2">

    <div class="row justify-content-center text-center">
        <i class="fa fa-5x fa-exclamation-triangle primary-warn"></i>
    </div>
    <div class="row justify-content-center">
        <h3 class="col-md-auto"
            >By clicking the button below, you'll permanently close your account.</h3>
    </div>
    <div class="row justify-content-center">
        <p class="col-md-auto greyed-out"
           >Please be advised that closing your account does not absolve you from any
            overdue
            payment
            requests.</p>
    </div>
    <div class="row justify-content-center">
        <input (click)="flipAcceptCloseAccount()"
               [defaultChecked]="closeAccountAccepted"
               class="form-check-input rounded-5 form-control col-sm-auto"
               id="accept-close-account"
               type="checkbox">
        <label class="col-md-auto w-auto form-check-label"
               for="accept-close-account"
               >I understand that this action is permanent.</label>
    </div>
    <div class="row justify-content-center">
        <app-generic-button routerLink="/my-identity"
                            class="col-md-auto m-3"
                            [loading]="loading"
                            [buttonText]="'Back to safety'"
                            [btnClass]="'btn-basic-info'"
                            [icon]="'fa-solid fa-right-from-bracket'">
        </app-generic-button>
        <app-generic-button (click)="closeAccount()"
                            class="col-md-auto m-3"
                            [loading]="loading"
                            [buttonText]="'Yes, delete account'"
                            [btnClass]="'btn-primary-danger'"
                            [disabled]="loading || !closeAccountAccepted"
                            [icon]="'fa-solid fa-trash'">
        </app-generic-button>
    </div>
</div>
