import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ActivatedRoute, UrlSegment} from "@angular/router";
import {PaymentControllerService} from "../../common/services/api/payment-controller.service";


@Component({
  selector: 'app-payments',
  templateUrl: './payments-init.component.html',
  styleUrl: './payments-init.component.scss'
})
export class PaymentsInitComponent implements OnInit {

  @Output() hideMenu: EventEmitter<boolean> = new EventEmitter<boolean>()
  constructor(private activatedRoute: ActivatedRoute, private paymentCommandControllerService: PaymentControllerService) {
  }

  ngOnInit(): void {
    this.hideMenu.emit(true)
    this.activatedRoute.url.subscribe((urlSegments: UrlSegment[]) => {
      const paymentId = urlSegments[urlSegments.length - 1].path;
      this.paymentCommandControllerService.initiatePaymentIntent(paymentId)
          .subscribe({
            next: (response) => {
              window.location.href = response.redirectUri
            }
          });
    });
  }
}
