<div class="container" *ngIf="!preLoading">
    <form (submit)="authenticate()"
          class="row justify-content-center">
<!--  ^^autocomplete="off"       -->
        <app-logo [classes]="'mx-auto d-block mt-2'"
                  [height]="'200'"
                  [image]="'/doatoa-logo-full.svg'"
                  [width]="'300'"></app-logo>
        <div class="max-width-300 card p-3" *ngIf="!preLoading && !twoStepAuthenticationEnabled">
            <h2 class="text-center">Sign in</h2>
            <input [(ngModel)]="email"
                   class="rounded-5 mb-2 form-control"
                   datatype="email"
                   id="email"
                   name="email"
                   placeholder="Enter email"
                   type="text"
                   >
<!--     ^^autocomplete="new-username"       -->
            <div class="mb-2">
                <app-generic-button
                        id="sign-in"
                        (click)="method = 'doatoa'"
                        [widthClass]="''"
                        [type]="'submit'"
                        [disabled]="loading || email == '' || email == undefined"
                        [loading]="loading"
                        [buttonText]="'Sign in with DoaToa'"
                        [icon]="'fa-solid fa-right-to-bracket'">
                </app-generic-button>
            </div>
                <app-generic-button
                        id="sign-in-trezor"
                        (click)="method = 'trezor'"
                        [widthClass]="''"
                        [type]="'submit'"
                        [disabled]="loading || email == '' || email == undefined"
                        [loading]="loading"
                        [buttonText]="'Sign in with Trezor'"
                        [icon]="'fa-solid fa-right-to-bracket'">
                </app-generic-button>
            </div>
    </form>
    <div class="row m-3 justify-content-center">
        <div class="max-width-300 card text-center">
            <div class="p-2">New to DoaToa?
                <a routerLink="/sign-up">Sign up
                </a>
            </div>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="max-width-300 card text-center">
            <div class="p-2">Lost keys?
                <a routerLink="/recover-keys">Recover keys</a>
            </div>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="max-width-400 text-center">
            <app-error-modal [showModal]="errorOccurred"
                             [extraClasses]="'m-3'"
                             [message]="errorMessage"></app-error-modal>
        </div>
    </div>
    <div class="row justify-content-center">
        <img class="max-width-400 mx-auto" alt="sign-in" ngSrc="/sign-in.png" height="400" width="400">
    </div>
</div>

