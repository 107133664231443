import {NgModule} from '@angular/core';
import {CommonDoaToaModule} from "../../../../../doatoa-common/common-doatoa.module";
import {IdentitiesComponent} from "./identities.component";
import {IdentityReferenceComponent} from "./identity-reference/identity-reference.component";
import {RequestsComponent} from "./requests/requests.component";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {IdentityComponent} from "./identity/identity.component";
import {NgbCollapse} from "@ng-bootstrap/ng-bootstrap";

@NgModule({
    imports: [
        CommonDoaToaModule,
        InfiniteScrollModule,
        NgbCollapse
    ],
    declarations: [IdentitiesComponent, IdentityComponent, IdentityReferenceComponent, RequestsComponent]
})
export class IdentitiesModule { }