import {EventEmitter, Injectable} from '@angular/core';

import {CacheService} from "./cache-service";
import {DataService} from "./data.service";
import {OauthCommandControllerService} from "./api/oauth-command-controller-service";

@Injectable({
    providedIn: 'root'
})
export class TokenRefreshService {

    private lastRefresh: number = Date.now()
    private eventEmitter: EventEmitter<Event> = new EventEmitter<Event>();

    constructor(private dataService: DataService, private cacheService: CacheService, private authCommandControllerService: OauthCommandControllerService) {
        document.addEventListener('click', (event) => {
            this.eventEmitter.emit(event);
        });
        this.on('click', (event: Event) => {
            if (!(event.target instanceof Element)) {
                return;
            }
            const clickedElement = event.target as Element;
            const clickedElementId = clickedElement.id;
            if (this.shouldExcludeEvent(clickedElementId)) {
                return;
            }
            this.refreshTokensManually()
        });
    }

    private shouldExcludeEvent(elementId: string): boolean {
        const exclusions = Array.of(
            'username',
            'password',
            'sign-in',
            'password-reset',
            'sign-out-menu-item',
            'consent-button-true',
            'consent-button-false',
            'settings-sign-out-button'
        )
        return exclusions.includes(elementId)
    }

    on(eventName: string, callback: (event: Event) => void) {
        this.eventEmitter.subscribe((event: Event) => {
            if (eventName === event.type) {
                callback(event);
            }
        });
    }

    refreshTokens(intervalInMinutes: number) {
        setInterval(() => {
            this.refreshTokensManually()
        }, intervalInMinutes * 60 * 1000);
    }

    refreshTokensManually() {
        if (Date.now() - this.lastRefresh >= 60000) {
            const refreshToken = this.dataService.get('refreshToken')
            this.cacheService.bypassCache = true
            this.authCommandControllerService.refreshToken(refreshToken!)
                .subscribe({
                    next: (response) => {
                        this.dataService.setDurable('accessToken', response.access_token!)
                        this.dataService.setDurable('refreshToken', response.refresh_token!)
                        this.dataService.setDurable('idToken', response.id_token!)
                        this.dataService.setDurable('idTokenHint', response.id_token_hint!)
                        this.lastRefresh = Date.now()
                    }
                });
        }
    }
}
