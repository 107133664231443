<app-export-modal [customId]="exportId" [subject]="dataService.get('username')"></app-export-modal>
<app-new-request-modal (submittedRequest)="addRequest($event)"
                       [customId]="newRequestId"
                       [subject]="dataService.get('username')"
                       [title]="'Share your data'"
                       [shareModal]="true"></app-new-request-modal>
<app-new-claim-modal (claimView)="updateMyClaims($event)"
                     [customId]="newClaimId"
                     [subject]="dataService.get('username')"
                     [title]="'Make a new claim.'"></app-new-claim-modal>
<div class="card p-2 mt-2 mb-3">
    <div class="container">
        <div class="d-flex align-items-center justify-content-end">
            <app-error-modal *ngIf="errorOccurred"
                             [message]="errorMessage"
                             [showModal]="errorOccurred"></app-error-modal>
            <lib-generic-message class="mt-2 w-100"
                                 [placeHolder]="'My claims'"
                                 [showPlaceholder]="!showMessage"
                                 [message]="message"
                                 [show]="showMessage"></lib-generic-message>
            <app-action-button
                    class="mt-2 me-2"
                    [useSpinner]="false"
                    [showPrimary]="true"
                    [btnClass]="'btn-basic-info'"
                    [primaryIconClass]="'fa-solid fa-share-nodes'"
                    [attr.data-bs-target]="'#'+newRequestId"
                    data-bs-toggle="modal">
            </app-action-button>
            <app-action-button
                    class="mt-2"
                    [useSpinner]="false"
                    [showPrimary]="true"
                    [btnClass]="'btn-basic-info'"
                    [primaryIconClass]="'fa fa-cloud-arrow-down'"
                    [attr.data-bs-target]="'#'+exportId"
                    data-bs-toggle="modal">
            </app-action-button>
        </div>
        <div data-bs-toggle="modal"
             [attr.data-bs-target]="'#'+newClaimId"
             class="mt-3 mb-3 btn btn-basic-info secondary-blue d-flex justify-content-center align-items-center container position-relative height-40">
            New claim
            <i class="ms-2 fa fa-plus text-center"></i>
        </div>
        <app-spinner [loading]="loadingInitialClaims"
                     [style]="'width: 40px; height: 40px;'"></app-spinner>
    </div>
    <div class="container max-height-400 overflow-y-scroll">
        <div *ngFor="let claim of myClaims; index as i" class="container justify-content-center">
            <div *ngIf="loadingInitialClaims">...</div>
            <div *ngIf="!loadingInitialClaims" class="row text-dark">
                <div (click)="fetchClaim(claim)"
                     [attr.data-bs-target]="'#claim-'+claim.id"
                     [ngClass]="resolveClass(i, claim)"
                     [id]="'claim-row-'+claim.id"
                     aria-expanded="false"
                     data-bs-toggle="collapse"
                     class="mb-3 p-0 m-0 btn">
                    <div class="pe-0 ps-3 container d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center min-width-220">
                            <i class="fa fa-fw {{claim.aspects?.includes('verified') ? 'fa-check primary-blue' : 'fa-question greyed-out'}}"></i>
                            <div class="ms-2 text-start truncate-text">{{ claim.type |  toReadableString }}</div>
                            <i class="ms-2 fa fa-fw primary-blue {{claim.visibilityScope | toVisibilityScopeIcon}}"></i>
                        </div>
                        <app-action-button [useSpinner]="true"
                                           [loading]="loading.get(claim.id!) || false"
                                           [showPrimary]="open.get(claim.id!) || false"
                                           [showSecondary]="!open.get(claim.id!) || false"
                                           [btnClass]="'btn-basic-info'"
                                           [primaryIconClass]="'fa fa-chevron-up'"
                                           [secondaryIconClass]="'fa fa-chevron-down'">
                        </app-action-button>
                    </div>
                </div>
                <div class="collapse" [id]="'claim-'+claim.id">
                    <app-claim *ngIf="requestedClaims.get(claim.id!)"
                               [myProfile]="true"
                               (claimChanged)="updateClaimClass($event)"
                               (deletedClaimId)="removeClaimById($event)"
                               [claim]="requestedClaims.get(claim.id!)"
                               [claimReference]=claim></app-claim>
                </div>
            </div>
        </div>
    </div>
</div>