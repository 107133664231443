import {Component, Input} from '@angular/core';
import {LogoComponent} from "../../common/logo/logo.component";
import {NgIf} from "@angular/common";
import {GenericButtonComponent} from "../../common/generic-button/generic-button.component";
import {ErrorModalComponent} from "../../common/error-modal/error-modal.component";


@Component({
    selector: 'app-success-landing',
    templateUrl: './success-landing.component.html',
    standalone: true,
    imports: [
        LogoComponent,
        GenericButtonComponent,
        ErrorModalComponent,
        LogoComponent,
        NgIf,
        GenericButtonComponent,
        ErrorModalComponent
    ],
    styleUrl: './success-landing.component.scss'
})
export class SuccessLandingComponent {
    @Input() message: string = ''
    @Input() loading: boolean = false
    @Input() errorOccurred: boolean = false
}
