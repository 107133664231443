<div class="container">
    <div *ngFor="let request of identity?.requests; index as i" class="container justify-content-center">
        <div class="row text-dark">
            <div (click)="openOrClose(request.id!)"
                 [attr.aria-controls]="'request-'+request.id"
                 [attr.data-bs-target]="'#request-'+request.id"
                 aria-expanded="false"
                 data-bs-toggle="collapse"
                 class="mb-3 p-0 m-0 btn {{i % 2 == 0 ? 'btn-basic-secondary-dark' : 'btn-basic-secondary'}}">
                <div class="pe-0 ps-3 container d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                       <span class="col-lg-auto">{{ request.type | toReadableString }}
                           request by <span class="secondary-blue">{{ request.initiator }}</span> on:
                    <span *ngFor="let scope of request.scopes"
                          class="secondary-blue">{{ scope | toReadableString }}</span>
                    </span>
                    </div>
                    <app-action-button [showPrimary]="open.get(request.id!) || false"
                                       [showSecondary]="!open.get(request.id!) || false"
                                       [btnClass]="'btn-basic-info'"
                                       [primaryIconClass]="'fa fa-chevron-up'"
                                       [secondaryIconClass]="'fa fa-chevron-down'">
                    </app-action-button>
                </div>
            </div>
        </div>
        <div class="collapse"
             id="request-{{request.id}}">
            <app-request [request]="request"
                         (requestDeleted)="removeRequest($event)"
                         [addressedToMe]="false"></app-request>
        </div>
    </div>
    <div *ngIf="identity?.requests?.length == 0">
        <div class="mt-3 text-center greyed-out">No requests sent by you</div>
    </div>
</div>
