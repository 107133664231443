import {Component, Input} from '@angular/core';
import {
    IdentityCommandControllerService,
    IdentityView,
    ClaimCommandControllerService, FactClaimPayload, ClaimView, FactPayload
} from "../../../../../../doatoa-common/services";
import {HttpErrorResponse} from "@angular/common/http";
import {VisibilityScopeEnum} from "../../../../../../doatoa-common/services/model/visibility-scope";
import {CacheService} from "../../../../../../doatoa-common/services/cache-service";
import {DataService} from "../../../../../../doatoa-common/services/data.service";

@Component({
    selector: 'app-identity-summary',
    templateUrl: './profile-introduction.component.html',
    styleUrl: './profile-introduction.component.scss'
})
export class ProfileIntroductionComponent {

    @Input() loading: boolean = false
    loadingMap: Map<VisibilityScopeEnum, boolean> = new Map<VisibilityScopeEnum, boolean>()
    introductionUpdateLoading: boolean = false
    errorOccurred: boolean = false
    errorMessage: string = ''
    introduction: string = ''
    maxCharacters: number = 100
    remainingCharacters: number = 100

    constructor(protected dataService: DataService, private profileCommandControllerService: IdentityCommandControllerService, private claimCommandControllerService: ClaimCommandControllerService, private cacheService: CacheService) {
    }

    alterVisibility(visibilityScope: VisibilityScopeEnum) {
        this.loadingMap.set(visibilityScope, true)
        this.cacheService.bypassCache = true
        this.profileCommandControllerService.patchUser([{
            op: "replace",
            path: "/visibilityScope",
            value: visibilityScope.toLowerCase()
        }]).subscribe({
            next: (response) => {
                this.loadingMap.set(visibilityScope, false)
                const identity = this.dataService.get('identity') as IdentityView;
                identity!.visibilityScope = visibilityScope
                this.dataService.setNonDurable('identity', identity)
            },
            error: (error) => {
                this.loadingMap.set(visibilityScope, false)
                if (error instanceof HttpErrorResponse) {
                    this.errorOccurred = true
                }
            }
        })
    }

    submitIntroduction() {
        this.cacheService.bypassCache = true
        this.introductionUpdateLoading = true
        const factClaimPayload: FactClaimPayload = {
            factValue: this.introduction,
            claimType: "introduction",
            claimVisibilityScope: VisibilityScopeEnum.Public,
            factAspects: [],
            claimAspects: []
        }
        this.claimCommandControllerService.makeClaim(this.dataService.get('username'), factClaimPayload).subscribe({
            next: (response) => {
                const identity = this.dataService.get('identity') as IdentityView;
                identity!.claims?.push(response)
                identity!.introduction = this.introduction
                this.introductionUpdateLoading = false
                this.dataService.setNonDurable('identity', identity)
            },
            error: (error) => {
                this.introductionUpdateLoading = false
                if (error instanceof HttpErrorResponse) {
                    this.errorOccurred = true
                }
            }
        })
    }

    onIntroductionInput(event: any) {
        const enteredText = event.target.value;
        this.remainingCharacters = this.maxCharacters - enteredText.length;
    }

    protected readonly VisibilityScopeEnum = VisibilityScopeEnum;

}