import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
    ClaimQueryControllerService,
    ClaimReferenceView,
    ClaimView,
    IdentityView
} from "../../../../../../doatoa-common/services";
import * as uuid from "uuid";
import {DataService} from "../../../../../../doatoa-common/services/data.service";
import {IdentityReferenceView} from "../../../../../../doatoa-common/services/model/identity-reference-view";

@Component({
    selector: 'app-identity',
    templateUrl: './identity.component.html',
    styleUrl: './identity.component.scss'
})
export class IdentityComponent {

    @Input() identity?: IdentityView
    @Output() reloadCurrentProfile: EventEmitter<any> = new EventEmitter();
    errorOccurred: boolean = false
    claimLoading: Map<string, boolean> = new Map<string, boolean>()
    open: Map<string, boolean> = new Map<string, boolean>()
    @Input() classList: string = ''
    shortSummary: string = ''
    profileId?: string = 'my-identity-reference-' + uuid.v4()
    requestedClaimReference: ClaimReferenceView = {}
    requestedClaims: Map<string, ClaimView> = new Map<string, ClaimView>()
    loading: boolean = false;
    claimLoaded: boolean = false;

    constructor(private claimQueryControllerService: ClaimQueryControllerService, protected dataService: DataService) {
    }

    getClaim(claim: ClaimReferenceView) {
        if (!this.claimLoaded) {
            this.open.set(claim.id!, !this.open.get(claim?.id || '') || false)
            this.claimLoading.set(claim.id!, true)
            this.claimQueryControllerService.getClaim(claim!.id!)
                .subscribe({
                    next: (response) => {
                        this.claimLoading.set(claim.id!, false)
                        this.requestedClaimReference = claim
                        this.requestedClaims.set(claim.id!, response)
                        this.claimLoaded = true
                    },
                    error: (error) => {
                        this.claimLoading.set(claim.id!, false)
                        this.errorOccurred = true
                    }
                });
        } else {
            this.claimLoaded = !this.claimLoaded
        }
    }


    protected readonly sessionStorage = sessionStorage;
}
