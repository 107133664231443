import {NgModule} from '@angular/core';
import {CommonDoaToaModule} from "../../../../../doatoa-common/common-doatoa.module";
import {FactsComponent} from "./facts.component";

@NgModule({
    imports: [
        CommonDoaToaModule
    ],
    declarations: [FactsComponent]
})
export class FactsModule { }