import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DataService} from "../../../../../../doatoa-common/services/data.service";
import {RedirectService} from "../../../../../../doatoa-common/services/redirect-service";
import {SignOutService} from "../../../../../../doatoa-common/services/signout-service";

@Component({
  selector: 'app-sign-out-landing',
  templateUrl: './sign-out-landing.component.html',
  styleUrl: './sign-out-landing.component.scss'
})
export class SignOutLandingComponent implements OnInit{
  @Output() hideMenu: EventEmitter<boolean> = new EventEmitter<boolean>()
  constructor(private dataService: DataService, private signoutService: SignOutService, private redirectService: RedirectService) {
  }
    ngOnInit(): void {
      this.hideMenu.emit(true)
      const idTokenHint = this.dataService.get('idTokenHint')
      if (idTokenHint != null) {
        this.signoutService.signOut(idTokenHint!)
      }
      else this.redirectService.redirectToGateway()
    }

}
