import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import * as jwt_decode from "jwt-decode";
import {
    OauthCommandControllerService
} from "../../../../../../doatoa-common/services/api/oauth-command-controller-service";
import {DataService} from "../../../../../../doatoa-common/services/data.service";

@Component({
    selector: 'app-oauth-landing',
    templateUrl: './oauth-landing.component.html',
    styleUrl: './oauth-landing.component.scss'
})
export class OauthLandingComponent implements OnInit {

    loading: boolean = false
    queryParams?: any = undefined

    constructor(private oauthCommandControllerService: OauthCommandControllerService,
                private activatedRoute: ActivatedRoute,
                private dataService: DataService,
                private router: Router) {
    }


    ngOnInit() {
        this.activatedRoute.queryParamMap.subscribe((p: any) => {
            this.dataService.setNonDurable('queryParams', JSON.stringify(p.params))
            this.requestTokens()
        });
    }

    requestTokens() {
        const queryParams = JSON.parse(this.dataService.get('queryParams') || "{}")
        if (queryParams.code != undefined) {
            this.oauthCommandControllerService.exchangeAuthorizationCodeForToken(queryParams.code)
                .subscribe({
                    next: (response) => {
                        const idToken = response.id_token!
                        const decodedIdToken = this.getDecodedToken(idToken!)
                        this.dataService.setDurable('username', decodedIdToken.sub);
                        this.dataService.setDurable('license', decodedIdToken.license);
                        this.dataService.setDurable('accessToken', response.access_token!);
                        this.dataService.setDurable('refreshToken', response.refresh_token!);
                        this.dataService.setDurable('idToken', response.id_token!)
                        this.dataService.setDurable('idTokenHint', response.id_token_hint!)
                        this.router.navigate(['/my-identity']).catch(error => console.log(error))
                    }
                });
        }
    }

    getDecodedToken(token: string): any {
        try {
            return jwt_decode.jwtDecode(token)
        } catch (Error) {
            console.log(Error);
            return 'null'
        }
    }


}
