<div class="mt-3 m-auto">
    <div *ngIf="showPlaceholder" class="justify-content-center">
        <h5 class="text-center ps-5">{{placeHolder}}</h5>
    </div>
    <div *ngIf="show" class="justify-content-center">
        <div class="card text-center">
            <div class="p-3">
                <span class="text-center secondary-blue">{{message}}</span>
            </div>
        </div>
    </div>
</div>
