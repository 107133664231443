/**
 * DoaToa
 * Manage your data and your digital identities, your data your choice.
 *
 * The version of the OpenAPI document: 1.0.0-alpha
 * Contact: info@compilit.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import {Injectable} from '@angular/core';
import {HttpContext, HttpEvent, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';

import {JsonPatchPayload} from '../model/json-patch-payload';
import {IdentityPayload} from "../model/identity-payload";
import {AbstractHttpService} from "./abstract-http-service";
import {MetadataView} from "../model/metadata-view";
import {IdentityView} from "../model/identity-view";
import {IdentityReferenceView} from "../model/identity-reference-view";
import {addToHttpParams} from "./common-http-operations";
import {ActionView, RedirectView} from '../model/redirect-view';


@Injectable({
    providedIn: 'root'
})
export class IdentityControllerService extends AbstractHttpService {


    /**
     * Alter specific properties of this license using a json-patch+json subscription.
     * @param jsonPatchPayload
     * @param observe set whether to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report subscription and response progress.
     */
    public patchIdentity(jsonPatchPayload: Array<JsonPatchPayload>, token?: string): Observable<any>;

    public patchIdentity(jsonPatchPayload: Array<JsonPatchPayload>, token?: string): Observable<HttpResponse<any>>;

    public patchIdentity(jsonPatchPayload: Array<JsonPatchPayload>, token?: string): Observable<HttpEvent<any>>;

    public patchIdentity(jsonPatchPayload: Array<JsonPatchPayload>, token?: string): Observable<any> {
        if (jsonPatchPayload === null || jsonPatchPayload === undefined) {
            throw new Error('Required parameter jsonPatchPayload was null or undefined when calling patchProfile.');
        }

        let localVarHeaders = this.defaultHeaders;
        localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
        localVarHeaders = localVarHeaders.append('Accept', 'application/json')

        if (token != undefined) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + token);
        }

        localVarHeaders = localVarHeaders.set('Accept', 'json-patch+json');


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }


        let localVarPath = `/identity/`;
        return this.httpClient.request<any>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                body: jsonPatchPayload,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }


    /**
     * Create a new identity with these credentials
     * @param identityPayload
     * @param observe set whether to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report subscription and response progress.
     */
    public register(identityPayload: IdentityPayload): Observable<any>;

    public register(identityPayload: IdentityPayload): Observable<HttpResponse<any>>;

    public register(identityPayload: IdentityPayload): Observable<HttpEvent<any>>;

    public register(identityPayload: IdentityPayload): Observable<any> {
        if (identityPayload === null || identityPayload === undefined) {
            throw new Error('Required parameter identityPayload was null or undefined when calling register.');
        }

        let localVarHeaders = this.defaultHeaders;
        localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
        localVarHeaders = localVarHeaders.append('Accept', 'application/json')


        let localVarPath = `/identity/`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: identityPayload,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }

    /**
     * Create a new identity with these credentials
     * @param observe set whether to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report subscription and response progress.
     */
    public requestPasswordReset(did?: string): Observable<any>;

    public requestPasswordReset(did?: string): Observable<HttpResponse<any>>;

    public requestPasswordReset(did?: string): Observable<HttpEvent<any>>;

    public requestPasswordReset(did?: string): Observable<any> {
        let localVarHeaders = this.defaultHeaders;
        localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
        localVarHeaders = localVarHeaders.append('Accept', 'application/json')


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        // const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        // if (httpContentTypeSelected !== undefined) {
        localVarHeaders = localVarHeaders.set('Content-Type', 'application/json');
        // }


        let localVarPath = `/identities/`;
        const passwordResetPayload = {subject: did};
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                body: passwordResetPayload,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }

    /**
     * Create a new identity with these credentials
     * @param observe set whether to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report subscription and response progress.
     */
    public closeAccount(did: string): Observable<any>;

    public closeAccount(did: string): Observable<HttpResponse<any>>;

    public closeAccount(did: string): Observable<HttpEvent<any>>;

    public closeAccount(did: string): Observable<any> {
        let localVarHeaders = this.defaultHeaders;
        localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
        localVarHeaders = localVarHeaders.append('Accept', 'application/json')


        let localVarPath = `/identities/`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }

    /**
     * Get all available identities.
     * @param did The matching dids you wish to search for.
     * @param pageNumber The page number you wish to view.
     * @param pageSize The size of the page.
     * @param sortBy State by which property you wish to sort, defaults to \&#39;did\&#39;.
     * @param observe set whether to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report subscription and response progress.
     */
    public findIdentities(did?: string, requestStatus?: string, pageNumber?: number, pageSize?: number, sortBy?: string): Observable<Array<IdentityReferenceView>>;

    public findIdentities(did?: string, requestStatus?: string, pageNumber?: number, pageSize?: number, sortBy?: string): Observable<HttpResponse<Array<IdentityReferenceView>>>;

    public findIdentities(did?: string, requestStatus?: string, pageNumber?: number, pageSize?: number, sortBy?: string): Observable<HttpEvent<Array<IdentityReferenceView>>>;

    public findIdentities(did?: string, requestStatus?: string, pageNumber?: number, pageSize?: number, sortBy?: string): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (pageNumber !== undefined && pageNumber !== null) {
            localVarQueryParameters = addToHttpParams(localVarQueryParameters,
                <any>pageNumber, 'pageNumber');
        }
        if (pageSize !== undefined && pageSize !== null) {
            localVarQueryParameters = addToHttpParams(localVarQueryParameters,
                <any>pageSize, 'pageSize');
        }
        if (sortBy !== undefined && sortBy !== null) {
            localVarQueryParameters = addToHttpParams(localVarQueryParameters,
                <any>sortBy, 'sortBy');
        }
        if (did !== undefined && did != '') {
            localVarQueryParameters = addToHttpParams(localVarQueryParameters,
                <any>did, 'did');
        }
        if (requestStatus !== undefined && requestStatus != '') {
            localVarQueryParameters = addToHttpParams(localVarQueryParameters,
                <any>requestStatus, 'requestStatus');
        }

        let localVarHeaders = this.defaultHeaders;
        localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
        localVarHeaders = localVarHeaders.append('Accept', 'application/json')


        let localVarPath = `/identities/`;
        return this.httpClient.request<Array<IdentityReferenceView>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }

    /**
     * View the my-identity-reference of the current  identity?.
     * @param observe set whether to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report subscription and response progress.
     */
    public viewIdentity(did: string, ignoreCache?: boolean): Observable<IdentityView>;

    public viewIdentity(did: string, ignoreCache?: boolean): Observable<HttpResponse<IdentityView>>;

    public viewIdentity(did: string, ignoreCache?: boolean): Observable<HttpEvent<IdentityView>>;

    public viewIdentity(did: string, ignoreCache?: boolean): Observable<any> {

        let localVarHeaders = this.defaultHeaders;
        localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
        localVarHeaders = localVarHeaders.append('Accept', 'application/json')


        if (!!ignoreCache && ignoreCache) {
            localVarHeaders = localVarHeaders.set('ignore-cache', 'true')
        }

        let localVarPath = `/identities/`;
        return this.httpClient.request<IdentityView>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: {did: did},
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }


    /**
     * Retrieve your license data.
     * @param observe set whether to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report subscription and response progress.
     */
    public gatherMetadata(did: string): Observable<MetadataView>;

    public gatherMetadata(did: string): Observable<HttpResponse<MetadataView>>;

    public gatherMetadata(did: string): Observable<HttpEvent<MetadataView>>;

    public gatherMetadata(did: string): Observable<any> {

        let localVarHeaders = this.defaultHeaders;
        localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
        localVarHeaders = localVarHeaders.append('Accept', 'application/json')


        let localVarPath = `/identities/metadata/?did=${did}`
        return this.httpClient.request<MetadataView>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }

    startVerification(did: string, chosenMethod: string, scopes: string[]): Observable<ActionView<string>> {
        let localVarHeaders = this.defaultHeaders;
        localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
        localVarHeaders = localVarHeaders.append('Accept', 'application/json')

        let localVarPath = `/identity/verification/`;
        return this.httpClient.request<ActionView<string>>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: {
                    did: did, language: "EN_en", verificationMethod: chosenMethod, scopes: scopes
                },
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }
}
