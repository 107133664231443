import {Component, EventEmitter, OnInit, Output} from '@angular/core';

import {SafeResourceUrl} from "@angular/platform-browser";
import * as uuid from "uuid";
import {ClaimReferenceView} from "../common/services";
import {DataService} from "../common/services/data-storage.service";


@Component({
    selector: 'app-my-identity',
    templateUrl: './my-identity.component.html',
    styleUrls: ['./my-identity.component.scss']
})
export class MyIdentityComponent implements OnInit {

    @Output() claimViewEmitter: EventEmitter<ClaimReferenceView> = new EventEmitter<ClaimReferenceView>()
    @Output() pageLoading: EventEmitter<boolean> = new EventEmitter<boolean>()
    @Output() myProfileLoading: EventEmitter<boolean> = new EventEmitter<boolean>()
    protected myProfileId: string = 'my-identity-reference-' + uuid.v4()
    protected loading: boolean = !this.dataService.has('identity')
    protected did?: string = undefined
    protected queryParams?: any = undefined
    protected loadingAvatar: boolean = false
    protected errorOccurred: boolean = false
    protected errorMessage: string = ''
    protected flipOpenForRequestsLoading: boolean = false
    protected imageToUpload?: Promise<ArrayBuffer> = undefined
    protected imagePath?: SafeResourceUrl = undefined

    constructor(protected dataService: DataService) {
    }

    ngOnInit() {
        this.pageLoading.emit(false)
        if (!this.dataService.has('identity')) {
            this.dataService.init()
            this.dataService.currentLoadingState.subscribe(currentLoadingState => {
                this.loading = currentLoadingState
            })
        }
    }
    //
    // protected removeRequest($event: SubscriptionView) {
    //     const currentIdentity = this.dataService.getIdentity() as IdentityView
    //     currentIdentity?.subscriptions!.forEach((element, index) => {
    //         if (currentIdentity?.subscriptions![index].id == $event.id) {
    //             currentIdentity?.subscriptions!.splice(index, 1);
    //         }
    //     });
    //     this.dataService.publish('identity', currentIdentity)
    // }

    // protected updateRequest($event: SubscriptionView) {
    //     const currentIdentity = this.dataService.getIdentity() as IdentityView
    //     currentIdentity?.subscriptions!.forEach((element, index) => {
    //         if (currentIdentity?.subscriptions![index].id == $event.id) {
    //             currentIdentity!.subscriptions![index] = $event
    //         }
    //     });
    //     this.dataService.publish('identity', currentIdentity)
    // }

    // protected updateMyClaims(claimView: ClaimReferenceView) {
    //     const currentIdentity = this.dataService.getIdentity() as IdentityView
    //     currentIdentity?.claims?.push(claimView)
    //     this.dataService.publish('identity', currentIdentity)
    // }

}
