import {Component, OnInit} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {
  ApiStatusQueryControllerService
} from "../../../../../../doatoa-common/services/api/api-status-query-controller.service";
import {CacheService} from "../../../../../../doatoa-common/services/cache-service";
import {SpinnerComponent} from "../../../../../../doatoa-common/spinner/spinner.component";
import {GenericButtonComponent} from "../../../../../../doatoa-common/generic-button/generic-button.component";
import {ErrorModalComponent} from "../../../../../../doatoa-common/error-modal/error-modal.component";

@Component({
  selector: 'app-api-status',
  standalone: true,
  imports: [CommonModule, SpinnerComponent, GenericButtonComponent, ErrorModalComponent, NgOptimizedImage],
  templateUrl: './api-status.component.html',
  styleUrl: './api-status.component.scss'
})
export class ApiStatusComponent implements OnInit {
  loading: boolean = false
  apiStatus: string = ''
  databaseStatus: string = ''
  apiLiveness: string = ''
  apiReadiness: string = ''

  constructor(private cacheService: CacheService, private apiStatusQueryControllerService: ApiStatusQueryControllerService) {
  }

  ngOnInit(): void {
    this.refreshApiStatus()
  }

  refreshApiStatus() {
    this.loading = true
    this.cacheService.bypassCache = true
    this.apiStatusQueryControllerService.checkApiStatus()
        .subscribe({
              next: value => {
                this.apiStatus = value?.status || "DOWN"
                this.databaseStatus = value?.components?.neo4j?.status || "DOWN"
                this.apiLiveness = value?.components?.livenessState?.status || "DOWN"
                this.apiReadiness = value?.components?.readinessState?.status || "DOWN"
                this.loading = false
              },
              error: value => {
                this.apiStatus = value?.error?.status || "DOWN"
                this.databaseStatus = value?.error?.components?.neo4j?.status || "DOWN"
                this.apiLiveness = value?.error?.components?.livenessState?.status || "DOWN"
                this.apiReadiness = value?.error?.components?.readinessState?.status || "DOWN"
                this.loading = false
              }
            }
        );
  }
}
