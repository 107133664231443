import {Component, OnInit} from '@angular/core';
import {IdentityCommandControllerService} from "../../../../../doatoa-common/services";
import {HttpErrorResponse} from "@angular/common/http";
import {Router} from "@angular/router";
import {environment} from "../../../../../doatoa-common/environments/environment";
import {RedirectService} from "../../../../../doatoa-common/services/redirect-service";
import {DataService} from "../../../../../doatoa-common/services/data.service";
import {
    OauthCommandControllerService
} from "../../../../../doatoa-common/services/api/oauth-command-controller-service";

@Component({
    selector: 'app-close-account',
    templateUrl: './close-account.component.html',
    styleUrls: ['./close-account.component.scss']
})
export class CloseAccountComponent implements OnInit {

    loading: boolean = false
    closeAccountAccepted: boolean = false
    errorOccurred: boolean = false
    errorMessage: string = ''

    constructor(protected dataService: DataService, private redirectService: RedirectService, private clientCommandControllerService: IdentityCommandControllerService, private authCommandControllerService: OauthCommandControllerService, private router: Router) {
    }

    ngOnInit(): void {
    }

    flipAcceptCloseAccount() {
        this.closeAccountAccepted = !this.closeAccountAccepted
    }

    closeAccount() {
        if (this.closeAccountAccepted) {
            this.loading = true
            this.clientCommandControllerService.closeAccount()
                .subscribe({
                    next: (response) => {
                        const username = this.dataService.get('username')
                        this.authCommandControllerService.signOut(username!)
                            .subscribe({
                                next: (response) => {
                                    sessionStorage.clear()
                                    const redirectUri = response.redirectUri
                                    if (!!redirectUri) {
                                        window.location.href = redirectUri
                                    } else {
                                        this.redirectService.redirectToGateway()
                                    }
                                    this.loading = false
                                },
                                error: (error) => {
                                    this.loading = false
                                    if (error instanceof HttpErrorResponse) {
                                        this.errorOccurred = true
                                    }
                                }
                            });
                    },
                    error: (error) => {
                        this.loading = false
                        if (error instanceof HttpErrorResponse) {
                            this.errorOccurred = true
                        }
                    }
                });
        }
    }
}
