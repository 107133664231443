<div class="container">
    <div class="row justify-content-center">
        <app-logo [classes]="'mx-auto d-block mt-2'"
                  [height]="'200'"
                  [image]="'/doatoa-logo-full.svg'"
                  [width]="'300'"></app-logo>
        <div class="col-auto text-center card max-width-300 p-3">
            <div *ngIf="mnemonic != ''" id="mnemonic-phrase" class="card btn p-3 mb-2" (click)="copyToClipboard()">
                <p>This is your mnemonic recovery phrase, take extra care to store it somewhere safe (preferably offline). This phrase cannot be recreated. Once lost, you'll never be able to recover your digital identity if you've also lost your keys.</p>
                <div class="container">
                    <div class="row">
                        <div *ngFor="let m of mnemonicParts; index as i" class="col-6 d-flex">
                            <span class="me-2">{{ i + 1 }}</span>
                            <span class="fw-bold">{{ m }}</span>
                        </div>
                    </div>
                </div>
                <div class="text-center primary-blue" *ngIf="showCopyConfirmation">(Copied to clipboard)</div>
            </div>
            <div class="mb-2">
                <app-generic-button
                        (click)="createIdentity()"
                        [widthClass]="''"
                        [disabled]="loading"
                        [loading]="loading"
                        [buttonText]="'Next'"
                        [icon]="'fa fa-angle-right'"></app-generic-button>
            </div>
        </div>
    </div>
</div>
