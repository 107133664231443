/// <reference types="@angular/localize" />

import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from "../../doatoa-common/environments/environment";

if (environment.production) {
    enableProdMode();
}

// Helper function
let domReady = ({cb}: { cb: any }) => {
    document.readyState === 'interactive' || document.readyState === 'complete'
        ? cb()
        : document.addEventListener('DOMContentLoaded', cb);
};

domReady({
    cb: () => {
        // Display body when DOM is loaded
        document.body.style.visibility = 'visible';
    }
});

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error({error: err}));