// app-routing.module.ts
import {inject, NgModule} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, RouterModule, RouterStateSnapshot, Routes} from '@angular/router';
import {MyIdentityComponent} from '../modules/my-identity/my-identity.component';
import {MyClaimsComponent} from "../modules/my-identity/my-claims/my-claims.component";
import {IdentitiesComponent} from "../modules/identities/identities.component";
import {FactsComponent} from "../modules/facts/facts.component";
import {SettingsComponent} from "../modules/settings/settings.component";
import {CloseAccountComponent} from "../modules/close-account/close-account.component";
import {ErrorComponent} from "../modules/error/error.component";
import {DeveloperConsoleComponent} from "../modules/developers/developer-console.component";
import {RedirectService} from "../../../../doatoa-common/services/redirect-service";
import {DataService} from "../../../../doatoa-common/services/data.service";
import {SignOutLandingComponent} from "../modules/landing/sign-out-landing/sign-out-landing.component";
import {PaymentsUpdateLandingComponent} from "../modules/landing/payments-update/payments-update-landing.component";
import {OauthLandingComponent} from "../modules/landing/oauth-landing/oauth-landing.component";
import {PaymentsInitComponent} from "../modules/landing/payments-init/payments-init.component";
import {OauthInitComponent} from "../modules/landing/oauth-init/oauth-init.component";

const canAccessDoaToa: CanActivateFn =
    (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        const redirectService = inject(RedirectService)
        const dataService = inject(DataService)
        const accessToken = dataService.get('accessToken')
        const refreshToken = dataService.get('refreshToken')
        const hasTokenPair = accessToken != null && refreshToken != null
        if (!hasTokenPair) {
            redirectService.redirectToAuthWebapp()
        }
        return hasTokenPair
    };

const routes: Routes = [
    {path: '', redirectTo: '/my-identity', pathMatch: 'full'},
    {path: 'payments/:id', component: PaymentsInitComponent},
    {path: 'payments-landing', component: PaymentsUpdateLandingComponent},
    {path: 'oauth-landing', component: OauthLandingComponent},
    {path: 'oauth-init', component: OauthInitComponent},
    {path: 'sign-out', component: SignOutLandingComponent},
    {path: 'error', component: ErrorComponent, canActivate: [canAccessDoaToa]},
    {path: 'settings', component: SettingsComponent, canActivate: [canAccessDoaToa]},
    {path: 'developers-console', component: DeveloperConsoleComponent, canActivate: [canAccessDoaToa]},
    {path: 'my-identity', component: MyIdentityComponent, canActivate: [canAccessDoaToa]},
    {path: 'my-claims', component: MyClaimsComponent, canActivate: [canAccessDoaToa]},
    {path: 'close-account', component: CloseAccountComponent, canActivate: [canAccessDoaToa]},
    {path: 'identities', component: IdentitiesComponent, canActivate: [canAccessDoaToa]},
    {path: 'facts', component: FactsComponent, canActivate: [canAccessDoaToa]}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true})],
    exports: [RouterModule]
})
export class AppRoutingModule {

}
