import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2} from '@angular/core';
import {
    ClaimQueryControllerService,
    ClaimReferenceView,
    ClaimView,
    RequestView,
} from "../../../../../../doatoa-common/services";
import {DataService} from "../../../../../../doatoa-common/services/data.service";
import * as uuid from "uuid";

@Component({
    selector: 'app-my-claims',
    templateUrl: './my-claims.component.html',
    styleUrls: ['./my-claims.component.scss']
})
export class MyClaimsComponent {

    @Output() claimIdToFetchEmitter = new EventEmitter<string>()
    @Output() reloadProfileEmitter = new EventEmitter<Array<ClaimReferenceView>>()
    @Input() myClaims!: Array<ClaimReferenceView>
    @Input() scopes: Array<string> = [];
    newRequestId?: string = 'new-request-' + uuid.v4()
    exportId?: string = 'export-' + uuid.v4()
    newClaimId?: string = 'new-claim-' + uuid.v4()
    deleteClaimClicked: boolean = false
    loading: Map<string, boolean> = new Map<string, boolean>()
    open: Map<string, boolean> = new Map<string, boolean>()
    errorOccurred: boolean = false
    claimType: string = ''
    claimToDelete: ClaimReferenceView = {aspects: []}
    requestedClaimReference: ClaimReferenceView = {aspects: []}
    requestedClaims: Map<string, ClaimView> = new Map<string, ClaimView>()
    errorMessage: string = ''
    scope?: string = undefined
    showMessage: boolean = false;
    message: string = '';

    @Input() loadingInitialClaims: boolean = false;
    protected readonly sessionStorage = sessionStorage;


    constructor(private renderer: Renderer2, private elementRef: ElementRef, protected dataService: DataService, private claimQueryControllerService: ClaimQueryControllerService) {
    }

    updateMyClaims($event: ClaimReferenceView) {
        this.myClaims.push($event);
    }

    tryDeleteClaim(claim: ClaimReferenceView) {
        this.deleteClaimClicked = true
        this.claimToDelete = claim
    }

    claimIsMandatory(claim: ClaimReferenceView) {
        let isMandatory = false;
        for (const aspect of claim.aspects!) {
            if (aspect == "mandatory") {
                isMandatory = true
            }
        }
        return isMandatory;
    }


    fetchClaim(claim: ClaimReferenceView) {
        this.open.set(claim.id!, !this.open.get(claim?.id || '') || false)
        this.loading.set(claim.id!, true)
        this.claimQueryControllerService.getClaim(claim!.id!)
            .subscribe({
                next: (response) => {
                    this.loading.set(claim.id!, false)
                    this.requestedClaimReference = claim
                    this.requestedClaims.set(claim.id!, response)
                },
                error: (error) => {
                    this.loading.set(claim.id!, false)
                    this.errorOccurred = true
                }
            });
    }

    isLoading(claimId: string): boolean {
        return this.loading.has(claimId) && this.loading.get(claimId)!
    }

    removeClaimById(deletedClaimId: any) {
        this.myClaims.forEach((element, index) => {
            if (this.myClaims[index].id == deletedClaimId) this.myClaims.splice(index, 1);
            this.message = 'Claim deleted!'
            this.showMessage = true
        });
    }

    reloadProfile() {
        this.reloadProfileEmitter.emit()
    }

    updateClaim(updatedClaim: ClaimReferenceView) {
        this.requestedClaimReference = updatedClaim
    }

    resolveClass(index: number, claim: ClaimReferenceView) : string {
        if (claim.events?.some((x, y) => x.type == 'pending')) {
            return 'btn-basic-notify'
        }
        return index % 2 == 0 ? 'btn-basic-secondary-dark' : 'btn-basic-secondary';
    }

    updateClaimClass($event: string) {
        document.getElementById('claim-row-' + $event)
        const element = this.elementRef.nativeElement.querySelector('#claim-row-' + $event);
        const claimView = this.myClaims.find((c,i) => c.id == $event)
        const index = this.myClaims.indexOf(claimView!)
        this.renderer.addClass(element, index % 2 == 0 ? 'btn-basic-secondary-dark' : 'btn-basic-secondary');
    }

    addRequest($event: RequestView) {
        this.dataService.get('identity').requests!.push($event)
    }

}
