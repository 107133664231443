<div class="container">
    <div class="row justify-content-center">
        <app-logo [classes]="'mx-auto d-block mt-2'"
                  [height]="'200'"
                  [image]="'/doatoa-logo-full.svg'"
                  [width]="'300'"></app-logo>
        <div class="col-auto text-center card max-width-300 p-3">
            <div class="card btn p-3">
                <p>In order to register, a one-time contribution is required.</p>
            </div>
            <div class="form-check rounded-5 col-auto m-3">
                <label for="termsAndConditionsAccepted">I accept the
                    <a href="https://doatoa.com/terms-of-service/" target=”_blank”>terms and conditions.</a>
                </label>
                <input [checked]="termsAndConditionsAccepted"
                       (change)="switchTermsAndConditionsAccepted()"
                       class="form-check-input"
                       id="termsAndConditionsAccepted"
                       type="checkbox">
            </div>
            <div class="mb-2">
                <app-generic-button
                        (click)="initiatePayment()"
                        [widthClass]="''"
                        [disabled]="!termsAndConditionsAccepted || loading"
                        [loading]="loading"
                        [buttonText]="'Initiate payment'"
                        [icon]="'fa-solid fa-cash-register'"></app-generic-button>
            </div>
        </div>
    </div>
</div>
