import {NgModule} from '@angular/core';
import {CommonDoaToaModule} from "../../../../../doatoa-common/common-doatoa.module";
import {AccountComponent} from "./account/account.component";
import {AdminComponent} from "./admin/admin.component";
import {DevelopersComponent} from "./developers/developers.component";
import {LicenseComponent} from "./license/license.component";
import {LicenseIconComponent} from "./license-icon/license-icon.component";
import {SettingsComponent} from "./settings.component";

@NgModule({
    imports: [
        CommonDoaToaModule
    ],
    declarations: [AccountComponent, AdminComponent, DevelopersComponent, LicenseComponent, LicenseIconComponent, SettingsComponent]
})
export class SettingsModule {
}