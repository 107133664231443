<div class="card p-2 mb-3 height-220">
    <div class="container">
        <div class="row align-items-center">
            <div *ngIf="this.dataService.get('identity')?.username != undefined"
                 class="h2 text-center truncate-text p-2">{{ this.dataService.get('identity')!.preferredUsername || this.dataService.get('identity')!.username }}
            </div>
        </div>
        <div class="row align-items-center height-90">
            <div *ngIf="this.dataService.get('identity')?.introduction == undefined" class="col greyed-out" >Describe yourself in one sentence.</div>
            <div *ngIf="this.dataService.get('identity')?.introduction != undefined" class="col overflow-y-scroll p-2" >{{ this.dataService.get('identity')!.introduction }}</div>
        </div>
        <div class="d-flex justify-content-end">
            <div class="d-flex border rounded-5 p-1 secondary-blue">
                <app-action-button *ngFor="let visibilityScope of VisibilityScopeEnum.values(); let first = first; let last = last;"
                                   (click)="alterVisibility(visibilityScope)"
                                   [btnClass]="this.dataService.get('identity')?.visibilityScope == visibilityScope ? 'btn-basic-info' : 'btn-basic-disabled'"
                                   [loading]="loadingMap.get(visibilityScope) || false"
                                   [useSpinner]="true"
                                   [showPrimary]="this.dataService.get('identity')?.visibilityScope == visibilityScope"
                                   [showSecondary]="this.dataService.get('identity')?.visibilityScope != visibilityScope"
                                   [primaryIconClass]="visibilityScope | toVisibilityScopeIcon"
                                   [secondaryIconClass]="visibilityScope | toOppositeVisibilityScopeIcon"
                                   class="{{last ? 'ms-1' : first ? 'settings-1' : 'ms-1 settings-1'}} ">
                </app-action-button>
            </div>
            <div class="d-flex ms-1 pt-1 pb-1 secondary-blue">
                <app-action-button
                        [showPrimary]="true"
                        [useSpinner]="true"
                        [loading]="introductionUpdateLoading"
                        [btnClass]="'btn-basic-info'"
                        [primaryIconClass]="'fa fa-pencil'"
                        data-bs-target="#introductionModal"
                        data-bs-toggle="modal">
                </app-action-button>
            </div>
        </div>
    </div>
</div>

<div aria-hidden="true"
     aria-labelledby="introductionModalLabel"
     class="modal fade"
     id="introductionModal"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog"
         role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"
                    >What do you wish to share about yourself?</h5>
                <button aria-label="Close"

                        class="btn-close float-end m-1"
                        data-bs-dismiss="modal"
                        type="button">
                </button>
            </div>
            <div class="modal-body">
                <textarea (reset)="submitIntroduction()"
                          (input)="onIntroductionInput($event)"
                          [(ngModel)]="introduction"
                          class="form-control rounded-1"
                          id="summaryText"
                          name="value"
                          [value]="this.dataService.get('identity')?.introduction"
                          placeholder="Value"
                          required>
                </textarea>
            </div>
            <div class="modal-footer">
                <button (click)="submitIntroduction()"
                        class="btn btn-basic-info {{introduction == '' ? 'disabled' : ''}}"
                        data-bs-dismiss="modal"

                        type="button">Submit
                </button>
            </div>
            <div class="mb-3">
                <span>Characters remaining: {{ remainingCharacters }}</span>
            </div>
            <app-error-modal [useTimout]="false"
                             [showModal]="remainingCharacters < 0"
                             [message]="'Please be less wordy...'"></app-error-modal>
        </div>
    </div>
</div>