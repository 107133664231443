/**
 * DoaToa
 * Manage your data and your digital identities, your data your choice.
 *
 * The version of the OpenAPI document: 1.0.0-alpha
 * Contact: info@compilit.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import {Injectable} from '@angular/core';
import {HttpContext, HttpEvent, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';

// @ts-ignore
// @ts-ignore
import {BASE_PATH, COLLECTION_FORMATS} from '../variables';
import {LicenseDetailsView} from "../model/license-details-view";
import {AbstractHttpService} from "./abstract-http-service";
import { RedirectView } from '../model/redirect-view';


@Injectable({
    providedIn: 'root'
})
export class PaymentControllerService extends AbstractHttpService {


    /**
     * Create a new identity with these credentials
     * @param observe set whether to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report subscription and response progress.
     */
    public getLicenses(options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<Array<LicenseDetailsView>>;

    public getLicenses(options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<HttpResponse<Array<LicenseDetailsView>>>;

    public getLicenses(options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<HttpEvent<any>>;
    getLicenses(extraHttpRequestParams?: any): Observable<{}> {
        let localVarHeaders = this.defaultHeaders;
        localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
        localVarHeaders = localVarHeaders.append('Accept', 'application/json')

        

        

        let localVarPath = `/license-details/`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }

    initiatePaymentIntent(did: string) : Observable<RedirectView> {
        let localVarHeaders = this.defaultHeaders;
        localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
        localVarHeaders = localVarHeaders.append('Accept', 'application/json')
        const didParam = encodeURIComponent(did)
        let localVarPath = `/identity/payments/?did=${didParam}`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }
}
