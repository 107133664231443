import {Component} from '@angular/core';
import {GenericButtonComponent} from "../../common/generic-button/generic-button.component";
import {LogoComponent} from "../../common/logo/logo.component";
import {NgForOf, NgIf} from "@angular/common";
import {Router} from "@angular/router";
import {PaymentControllerService} from "../../common/services/api/payment-controller.service";
import {DataService} from "../../common/services/data-storage.service";

@Component({
    selector: 'app-payment',
    standalone: true,
    imports: [
        GenericButtonComponent,
        LogoComponent,
        NgForOf,
        NgIf
    ],
    templateUrl: './payment.component.html',
    styleUrl: './payment.component.scss'
})
export class PaymentComponent {
    protected termsAndConditionsAccepted: boolean = false
    protected loading: boolean = false;

    constructor(private router: Router, private dataService: DataService, private paymentControllerService: PaymentControllerService) {
    }

    switchTermsAndConditionsAccepted() {
        this.termsAndConditionsAccepted = !this.termsAndConditionsAccepted;
    }

    initiatePayment() {
        this.loading = true
        this.dataService.last<string>('did').subscribe({
            next: did => {
                this.paymentControllerService.initiatePaymentIntent(did).subscribe({
                    next: redirectView => {
                        window.location.href = redirectView.redirectUri
                        this.loading = false
                    }
                })
            }
        })

    }

}
