<nav class="navbar navbar-expand-md bg-white p-0" *ngIf="!hideMenu">
    <div class="container-fluid justify-content-center">
        <div class="min-width-100 nav-item d-flex justify-content-center d-md-none m-1">
            <app-logo class="col"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                      (click)="open = !open"
                      [classes]="'m-auto'"
                      [height]="'60'"
                      [image]="'assets/img/doatoa-logo-full.svg'"
                      [width]="'180'"></app-logo>
        </div>
        <div class="container collapse navbar-collapse"
             id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto justify-content-center">
                <li class="btn min-width-100 d-none d-md-block">
                    <a href="https://doatoa.com">
                    <app-logo class="col"
                              (click)="open = !open"
                              [classes]="'m-auto'"
                              [height]="'60'"
                              [image]="'assets/img/doatoa-logo-full.svg'"
                              [width]="'120'"></app-logo></a>
                </li>
                <li ariaCurrentWhenActive='page'
                    class="btn min-width-100 nav-item pt-3 {{!dataService.has('accessToken') ? 'disabled' : ''}}"
                    routerLink="/settings"
                    routerLinkActive="active">
                    <i class="fa fa-gears"></i>
                    <div class="p-0"
                         >Settings
                    </div>
                </li>
                <li ariaCurrentWhenActive='page'
                    class="btn min-width-100 nav-item pt-3 {{!dataService.has('accessToken') ? 'disabled' : ''}}"
                    routerLink="/my-identity"
                    routerLinkActive="active">
                    <i class="fa fa-user"></i>
                    <div >My identity
                    </div>
                </li>
                <li ariaCurrentWhenActive='page'
                    class="btn min-width-100 nav-item pt-3 {{!dataService.has('accessToken') ? 'disabled' : ''}}"
                    routerLink="/identities"
                    routerLinkActive="active">
                    <i class="fa fa-users"></i>
                    <div>Identities</div>
                </li>
                <li ariaCurrentWhenActive='page'
                    class="btn min-width-100 nav-item pt-3 {{!dataService.has('accessToken') ? 'disabled' : ''}}"
                    routerLink="/facts"
                    routerLinkActive="active">
                    <i class="fa fa-gem"></i>
                    <div>Facts</div>
                </li>
                <li ariaCurrentWhenActive='page'
                    id="sign-out-menu-item"
                    class="btn min-width-100 nav-item pt-3"
                    routerLinkActive="active"
                    (click)="signOut()">
                    <i *ngIf="!logoutIsLoading" class="fa-solid fa-right-from-bracket"></i>
                    <app-spinner [loading]="logoutIsLoading"></app-spinner>
                    <div *ngIf="!logoutIsLoading">Sign out
                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>
<hr class="mt-0">