import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DataService} from "../common/services/data-storage.service";
import {SignOutService} from "../common/services/signout-service";
import {RedirectService} from "../common/services/redirect-service";
import {LogoComponent} from "../common/logo/logo.component";
import {SpinnerComponent} from "../common/spinner/spinner.component";

@Component({
    selector: 'app-sign-out',
    templateUrl: './sign-out.component.html',
    standalone: true,
    imports: [
        LogoComponent,
        SpinnerComponent
    ],
    styleUrl: './sign-out.component.scss'
})
export class SignOutComponent implements OnInit {
    @Output() hideMenu: EventEmitter<boolean> = new EventEmitter<boolean>()

    constructor(private dataService: DataService, private signoutService: SignOutService, private redirectService: RedirectService) {
    }

    ngOnInit(): void {
        this.hideMenu.emit(true)
        if (!this.dataService.has('idTokenHint')) {
            this.dataService.remove("accessToken")
            this.dataService.remove("did")
            this.dataService.remove("idToken")
            this.dataService.remove("refreshToken")
            this.redirectService.redirectToGateway()
        }
        this.dataService.last<string>('idTokenHint').subscribe({
            next: idTokenHint => {
                if (idTokenHint != undefined) {
                    this.signoutService.signOut(idTokenHint!)
                } else this.redirectService.redirectToGateway()
            }
        })

    }

}
