import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {EventView} from "../services";

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrl: './event.component.scss'
})
export class EventComponent {

  @Input() event: EventView = {}
}
