import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'toCurrencySymbol',
})
export class ToCurrencySymbolPipe implements PipeTransform {
    constructor() {
    }

    public transform(value: string | undefined): string {
        if (value == undefined)
            return ''
        switch (value) {
            case 'EUR': return 'fa-solid fa-euro-sign'
            case 'USD': return 'fa-solid fa-dollar-sign'
            default: return ''
        }
    }
}