import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DeveloperConsoleComponent} from "./developer-console.component";
import {CommonDoaToaModule} from "../../../../../doatoa-common/common-doatoa.module";
import {ApiStatusComponent} from "./api-status/api-status.component";


@NgModule({
    declarations: [DeveloperConsoleComponent],
    imports: [
        CommonModule,
        ApiStatusComponent,
        CommonDoaToaModule
    ]
})
export class DevelopersModule {
}
