import {Component, Input} from '@angular/core';
import {ClaimView, RequestView, IdentityView} from "../../../../../../doatoa-common/services";

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrl: './requests.component.scss'
})
export class RequestsComponent {
    loading: Map<string, boolean> = new Map<string, boolean>()
    errorOccurred: boolean = false
    @Input() identity?: IdentityView = {}
    requestToEdit: RequestView = {}
    open: Map<string, boolean> = new Map<string, boolean>()
    showMessage: boolean = false;
    message: string = '';

    resolveClass(index: number, claim: ClaimView): string {
        if (this.identity?.requests?.some((x, y) => x.status == 'approved' || x.status == 'denied')) {
            return 'btn-basic-notify'
        }
        return index % 2 == 0 ? 'btn-basic-secondary-dark' : 'btn-basic-secondary';
    }

    protected readonly sessionStorage = sessionStorage;

    openOrClose(id: string) {
        this.open.set(id, !this.open.get(id) || false)
    }

    removeRequest($event: RequestView) {
        this.identity!.requests!.forEach((element, index) => {
            if (this.identity!.requests![index].id == $event.id) this.identity!.requests!.splice(index, 1);
            this.message = 'Request deleted!'
            this.showMessage = true
        });
    }
}
