export type VisibilityScopeEnum =
    'public'
    | 'protected'
    | 'private'
    | 'none';
export const VisibilityScopeEnum = {
    Public: 'public' as VisibilityScopeEnum,
    Protected: 'protected' as VisibilityScopeEnum,
    Private: 'private' as VisibilityScopeEnum,
    None: 'none' as VisibilityScopeEnum,
    values(): Array<VisibilityScopeEnum> {
        return [this.Public, this.Protected, this.Private];
    }
}