import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {TokenRefreshService} from "../../../doatoa-common/services/token-refresh.service";
import {RedirectService} from "../../../doatoa-common/services/redirect-service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
    title = 'doatoa-resource-webapp';
    hideMenu: boolean = false;
    publicFactsLoading: boolean = false;
    myAccountLoading: boolean = false;
    myProfileLoading: boolean = false;
    publicProfilesLoading: boolean = false;

    constructor(private tokenRefreshService: TokenRefreshService) {
    }

    ngOnInit(): void {
        this.tokenRefreshService.refreshTokens(4)
    }

    onActivate($event: Event) {
        if ('hideMenu' in $event) {
            ($event as any).hideMenu.subscribe((loading: boolean) => {
                this.hideMenu = loading;
            });
        }
        if ('pageLoading' in $event) {
            ($event as any).pageLoading.subscribe((loading: boolean) => {
                this.hideMenu = loading;
            });
        }
        if ('publicFactsLoading' in $event) {
            ($event as any).publicFactsLoading.subscribe((loading: boolean) => {
                this.publicFactsLoading = loading;
            });
        }
        if ('myAccountLoading' in $event) {
            ($event as any).myAccountLoading.subscribe((loading: boolean) => {
                this.myAccountLoading = loading;
            });
        }
        if ('myProfileLoading' in $event) {
            ($event as any).myProfileLoading.subscribe((loading: boolean) => {
                this.myProfileLoading = loading;
            });
        }
        if ('publicProfilesLoading' in $event) {
            ($event as any).publicProfilesLoading.subscribe((loading: boolean) => {
                this.publicProfilesLoading = loading;
            });
        }
    }

}
