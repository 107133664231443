import {Component, OnInit} from '@angular/core';

import {ActivatedRoute} from "@angular/router";
import {LogoComponent} from "../../common/logo/logo.component";
import {SpinnerComponent} from "../../common/spinner/spinner.component";
import {DataService} from "../../common/services/data-storage.service";
import {PKCEService} from "../../common/services/pkce.service";
import {RedirectService} from "../../common/services/redirect-service";
import {OauthControllerService} from "../../common/services/api/oauth-controller.service";
import {randomString} from "../../common/services/random-strings";
import {environment} from "../../../environments/environment";

const TOKEN_LENGTH: number = 43;

@Component({
    selector: 'app-extension-landing',
    standalone: true,
    imports: [
        LogoComponent,
        SpinnerComponent
    ],
    templateUrl: './extension-landing.component.html',
    styleUrl: './extension-landing.component.scss'
})
export class ExtensionLandingComponent implements OnInit {

    protected message: string = 'Authorizing...';

    constructor(private activatedRoute: ActivatedRoute, private dataService: DataService, private pkceService: PKCEService, private redirectService: RedirectService, private oauthControllerService: OauthControllerService) {
    }

    ngOnInit(): void {
        this.activatedRoute.queryParamMap.subscribe((p: any) => {
            if (p.params.origin) {
                const origin = p.params.origin as string
                this.dataService.publish('final-destination', origin)
                this.pkceService.createCodeChallenge().then(codeChallenge => {
                    this.dataService.publish('code_verifier', codeChallenge.code_verifier)
                    const queryParams = new URLSearchParams({
                        state: randomString(43),
                        grant_type: 'authorization_code',
                        client_id: environment.webappExtensionClientId,
                        response_type: 'code,id_token',
                        response_mode: 'query',
                        code_challenge: codeChallenge.code_challenge,
                        code_challenge_method: 'S265',
                        scope: 'api,openid,profile',
                        nonce: randomString(43),
                        redirect_uri: `${environment.webappBaseUrl}/#/extension`,
                    });
                    this.redirectService.redirectToAuthWebappExtension(queryParams)
                })
            }
            if (p.params.code) {
                this.dataService.last<string>('final-destination').subscribe({next: origin => {
                        this.oauthControllerService.exchangeAuthorizationCodeForToken(p.params.code, environment.webappExtensionClientId, environment.webappBaseUrl + '/#/extension')
                            .subscribe({
                                next: (response) => {
                                    const idToken = response.id_token!
                                    window.postMessage(idToken)
                                    window.location.href = origin!
                                }
                            });
                    }})
            }
        })
    }

}
