import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {LicenseDetailsView} from "../../../../../../doatoa-common/services";
import LicenseNameEnum = LicenseDetailsView.LicenseNameEnum;


@Component({
    selector: 'app-license-icon',
    templateUrl: './license-icon.component.html',
    styleUrl: './license-icon.component.scss'
})
export class LicenseIconComponent implements OnChanges {

    @Input() licenseName?: LicenseNameEnum = undefined
    icons: string[] = []

    ngOnChanges(simpleChanges: SimpleChanges): void {
        const licenseName = simpleChanges['licenseName'].currentValue as LicenseNameEnum
        switch (licenseName) {
            case "freemium":
                this.icons.push("fa-solid fa-star")
                break;
            case "monthly_premium": {
                this.icons.push("fa-solid fa-star")
                this.icons.push("fa-solid fa-star")
            }
                break;
            case "yearly_premium": {
                this.icons.push("fa-solid fa-star")
                this.icons.push("fa-solid fa-star")
                this.icons.push("fa-solid fa-star")
            }
                break;
            case "admin":
            case "non_paying_premium": {
                this.icons.push("fa-solid fa-star")
                this.icons.push("fa-solid fa-star")
                this.icons.push("fa-solid fa-star")
                this.icons.push("fa-solid fa-star")
                this.icons.push("fa-solid fa-star")
            }
        }
    }


}
