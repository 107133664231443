import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {RedirectService} from "../../../../../../doatoa-common/services/redirect-service";

@Component({
    selector: 'app-oauth-init',
    templateUrl: './oauth-init.component.html',
    styleUrl: './oauth-init.component.scss'
})
export class OauthInitComponent implements OnInit {
    @Output() pageLoading: EventEmitter<boolean> = new EventEmitter<boolean>()

    constructor(private redirectService: RedirectService) {
    }

    ngOnInit(): void {
        this.redirectService.generateQueryParamsForAuthClient().then(queryParams => {
            this.redirectService.generateRedirectUri(queryParams).then(redirectUri => {
                window.location.href = redirectUri
            })
        });
    }
}
