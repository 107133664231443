import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ClaimCommandControllerService, ClaimReferenceView, ClaimView} from "../services";
import {HttpErrorResponse} from "@angular/common/http";
import {CacheService} from "../services/cache-service";
import {DataService} from "../services/data.service";

@Component({
  selector: 'app-delete-claim-modal',
  templateUrl: './delete-claim-modal.component.html',
  styleUrl: './delete-claim-modal.component.scss'
})
export class DeleteClaimModalComponent {

  deleted: boolean = false
  copyClicked: boolean = false
  deleteClaimClicked: boolean = false
  loading: Map<string, boolean> = new Map<string, boolean>()
  @Output() claimIdToFetchEmitter = new EventEmitter<string>()
  @Output() deletedClaimId = new EventEmitter<string>()
  errorOccurred: boolean = false
  claimType: string = ''
  @Input() claimToDelete: ClaimReferenceView = {}
  classes: string = ''
  errorMessage: string = ''
  scopes: Array<string> = []
  scope?: string = undefined

  constructor(private claimCommandControllerService: ClaimCommandControllerService, private cacheService: CacheService, private dataService: DataService) {
  }

  deleteClaim() {
    this.cacheService.bypassCache = true
    if (this.claimType == this.claimToDelete.type) {
      this.loading.set(this.claimToDelete.id!!, true)
      this.claimCommandControllerService.deleteClaim(this.claimToDelete.id!)
          .subscribe({
            next: (response) => {
              this.cacheService.clear()
              if (this.claimType == 'avatar') {
                this.dataService.remove('avatar')
                this.dataService.remove('avatarId')
              }
              this.loading.set(this.claimToDelete.id!!, false)
              this.deleteClaimClicked = false
              this.deletedClaimId.emit(this.claimToDelete.id)
              this.claimType = ''
              this.deleted = true;
              },
            error: (error) => {
              this.loading.set(this.claimToDelete.id!!, false)
              this.deleteClaimClicked = false
              this.claimType = ''
              if (error instanceof HttpErrorResponse) {
                  this.errorOccurred = true
              }
            }
          });
    }
  }

  nameMatches() {
    return this.claimToDelete.type === this.claimType;
  }

  copyText() {
    this.copyClicked = true
  }
}
