<div class="container p-3">
    <div class="row">
        <div class="col-xl-8 mb-4">
            <div class="card p-3">
                <h4>Authentication & authorization</h4>
                <div class="mb-1">DoaToa has its own IDP supporting Open ID Connect. This means you can request tokens (access, refresh and id) which you can use to access the DoaToa API or to identify the user in your own app.</div>
                <div class="mb-1">A refresh token has a far longer expiry time (24 hours) than an access token (5 minutes), you can use the refresh token to request a new access token and in the process acquire a new refresh token as well.</div>
                <div class="mb-3">The suggested flow to implement on the client side is as follows:</div>

                <h5>Non-concurrent/low traffic flow</h5>
                <p>If you are certain you will not use concurrent requests or don't expect high traffic, the simplest solution is:</p>
                <ol>
                    <li class="mb-1">authenticate: call the POST '/oauth2/sign-in/' endpoint with your credentials and receive the tokens.</li>
                    <li class="mb-1">before every request: call the PUT '/oauth2/tokens/' endpoint with the refresh token as payload and receive fresh tokens.</li>
                    <li class="mb-1">before every request (continued): add the access token to your Authorization header prefixed by 'Bearer ' (space included).</li>
                </ol>
                <h5>Concurrent/high traffic flow (possibility of multiple requests simultaneously)</h5>
                <p>If you are going to be sending concurrent requests, it is advisable to:</p>
                <ol>
                    <li class="mb-1">authenticate: call the POST '/oauth2/sign-in/' endpoint with your credentials and receive the tokens.</li>
                    <li class="mb-1">once per day, or every now and then: call the PUT '/oauth2/tokens/' endpoint with the refresh token as payload and receive fresh tokens.</li>
                    <li class="mb-1">before every request: add the access token to your Authorization header prefixed by 'Bearer ' (space included).</li>
                </ol>
                <p>Not following this advice will not break anything, but you're likely to receive (a lot) of 409 server responses due to the optimistic locking nature of DoaToa.</p>
                <app-generic-button
                        (click)="redirectToSwaggerUI()"
                        [disabled]="loading"
                        [loading]="loading"
                        [buttonText]="'API reference'"
                        [icon]="'fa-solid fa-book'">
                </app-generic-button>
            </div>
        </div>
        <app-api-status
                [ngClass]="'col-xl-4'"
        ></app-api-status>
    </div>
    <app-error-modal [showModal]="errorOccurred" [message]="errorMessage"></app-error-modal>
</div>