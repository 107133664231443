<div class="p-2 mb-4 search-results"
     infiniteScroll
     [scrollWindow]="false"
     [infiniteScrollDistance]="0"
     [infiniteScrollThrottle]="50"
     (scrolled)="onScrolledDown()">
    <form (submit)="findUsers()"
          id="profile-search"
          class="form-inline">
        <div class="row justify-content-center mb-2">
            <div class="container col-auto">
                <div class="row justify-content-center mb-2">
                    <input [(ngModel)]="username"
                           aria-label="username"
                           class="col-auto form-control max-width-300 rounded-5 height-40"
                           placeholder="Username"
                           type="search">
                </div>
                <div class="row justify-content-center mb-2">
                    <select (change)="setStatusFilter($event)"
                            class="col-auto form-select max-width-300 rounded-5 height-40"
                            id="requestStatusSelect"
                            name="type">
                        <option [selected]="defaultSelected" value="">{{ defaultRequestMessage }}
                        </option>
                        <option *ngFor="let status of ['PENDING', 'APPROVED', 'DENIED']"
                                [value]="status.toLowerCase()">Status: {{ status.toLowerCase() }}
                        </option>
                    </select>
                </div>
                <div class="row justify-content-center mb-2">
                    <app-generic-button
                            class="col-auto"
                            (click)="identities = []"
                            [disabled]="loading"
                            [buttonText]="'Search'"
                            [loading]="loading"
                            [icon]="'fa-solid fa-magnifying-glass'"
                            [type]="'submit'">
                    </app-generic-button>
                </div>
                <div class="row justify-content-center">
                    <app-generic-button
                            class="col-auto"
                            (click)="reset()"
                            [disabled]="loading"
                            [buttonText]="'Reset'"
                            [loading]="loading"
                            [icon]="'fa-solid fa-rotate'">
                    </app-generic-button>
                </div>
            </div>
        </div>
    </form>

    <div class="justify-content-center">
        <div *ngFor="let identity of identities; index as i">
            <app-identity-reference [scopes]="scopes"
                                    [index]="i"
                                    [classList]="'m-auto mt-3 mb-3'"
                                    [identityReference]="identity"></app-identity-reference>
        </div>
        <div *ngIf="identities == [] || identities?.length === 0"
             class="row justify-content-center greyed-out m-3"
             >No matching identities
        </div>
        <app-spinner [loading]="loading && identities.length > 0"
                     class="m-auto mt-5"
                     [style]="'width: 100px; height: 100px;'"></app-spinner>
    </div>
</div>