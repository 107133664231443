import {Component, EventEmitter, HostListener, OnInit, Output} from '@angular/core';
import {
    ClaimQueryControllerService,
    ClaimView, RequestView,
    IdentityQueryControllerService
} from "../../../../../doatoa-common/services";
import {Router} from "@angular/router";
import {HttpErrorResponse} from "@angular/common/http";
import {CacheService} from "../../../../../doatoa-common/services/cache-service";
import {IdentityReferenceView} from "../../../../../doatoa-common/services/model/identity-reference-view";

@Component({
    selector: 'app-identities',
    templateUrl: './identities.component.html',
    styleUrls: ['./identities.component.scss']
})
export class IdentitiesComponent implements OnInit {

    loading: boolean = false
    defaultSelected: boolean = false
    @Output() publicProfilesLoading: EventEmitter<boolean> = new EventEmitter<boolean>()
    scopes: Array<string> = []
    claimTypes: Array<string> = []
    identities: Array<IdentityReferenceView> = []
    username?: string = undefined
    defaultRequestMessage = 'No status filter'
    requestStatus?: string = this.defaultRequestMessage
    errorOccurred: boolean = false
    errorMessage: string = ''
    currentPage: number = 0
    pageSize: number = 10
    counter: number = 1
    protected readonly RequestView = RequestView;

    constructor(private cacheService: CacheService, private claimQueryControllerService: ClaimQueryControllerService, private userQueryControllerService: IdentityQueryControllerService, private router: Router) {
    }


    ngOnInit(): void {
        this.claimQueryControllerService.getClaimMetadata(undefined, "aggregates")
            .subscribe({
                next: (response) => {
                    this.scopes = response
                }
            });
    }

    reset() {
        this.defaultSelected = true
        this.identities = []
        this.requestStatus = this.defaultRequestMessage
        this.username = undefined
        this.pageSize = 10
        this.currentPage = 0
    }

    findUsers() {
        this.loading = true
        this.publicProfilesLoading.emit(this.loading)
        this.cacheService.bypassCache = true
        const queriedStatus = this.requestStatus == this.defaultRequestMessage ? undefined : this.requestStatus
        this.userQueryControllerService.findUsers(this.username, queriedStatus, this.currentPage, this.pageSize)
            .subscribe({
                next: (response) => {
                    this.identities.push(...response)
                    this.loading = false
                    this.publicProfilesLoading.emit(this.loading)
                },
                error: (error) => {
                    this.loading = false
                    this.publicProfilesLoading.emit(this.loading)
                    if (error instanceof HttpErrorResponse) {
                        this.errorOccurred = true
                    }
                }
            });
    }

    onScrolledDown() {
        this.currentPage++
        this.findUsers();
    }

    setStatusFilter($event: any) {
        this.requestStatus = $event.target.value
    }
}
