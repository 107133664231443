import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    ClaimCommandControllerService,
    ClaimQueryControllerService,
    ClaimReferenceView,
    FactClaimPayload,
    FactQueryControllerService
} from "../services";
import {Router} from "@angular/router";
import {Observable, ReplaySubject} from "rxjs";
import {CacheService} from "../services/cache-service";
import {VisibilityScopeEnum} from "../services/model/visibility-scope";
import {DataService} from "../services/data.service";

declare var $: any;

@Component({
    selector: 'app-new-claim-modal',
    templateUrl: './new-claim-modal.component.html',
    styleUrls: ['./new-claim-modal.component.scss']
})
export class NewClaimModalComponent implements OnInit {
    @Output() claimView: EventEmitter<ClaimReferenceView> = new EventEmitter<ClaimReferenceView>()
    @Input() proposed?: boolean = false;
    @Input() subject?: string;
    @Input() customId?: string;
    filePath: string = '';
    fileName: string = '';
    errorMessage: string = '';
    numOfFactValues: number = 1
    factToClaim: FactClaimPayload = {claimVisibilityScope: VisibilityScopeEnum.Protected, factAspects: [], claimAspects: []}
    errorOccurred: boolean = false
    loading: boolean = false
    isTextValue: boolean = false
    isImageValue: boolean = false
    claimCreationAccepted: boolean = false
    claimTypes: Array<string> = []
    defaultClaimMessage: string = 'Make a claim about...'
    defaultScopeMessage: string = 'Choose a category'
    selectedClaimTypeString?: string = this.defaultClaimMessage
    selectedClaimScopeString?: string = this.defaultScopeMessage
    selectedClaimType?: string
    selectedClaimScope?: string
    publicClaim: boolean = false;
    scopeSelected: boolean = false;
    claimMadeSuccessfully: boolean = false;

    protected readonly VisibilityScopeEnum = VisibilityScopeEnum;

    constructor(protected dataService: DataService, private cacheService: CacheService, private claimQueryControllerService: ClaimQueryControllerService, private claimCommandControllerService: ClaimCommandControllerService, private factQueryControllerService: FactQueryControllerService, private router: Router) {
    }

    ngOnInit(): void {
        this.claimQueryControllerService.getClaimMetadata("profile")
            .subscribe({
                next: (response) => {
                    this.claimTypes = response
                }
            });
    }

    tryMakeClaim() {
        this.factToClaim.claimType = this.selectedClaimTypeString
        if (this.factToClaim.claimType == 'account_email_address') {
            $('#changeAccountEmailModal').modal('show');
        } else {
            this.claimCreationAccepted = true
            this.makeClaim()
        }
    }

    makeClaim() {
        this.cacheService.bypassCache = true
        this.claimCreationAccepted = true
        if (this.factToClaim.claimType != undefined) {
            this.loading = true
            this.claimCommandControllerService.makeClaim(this.subject!!, this.factToClaim)
                .subscribe({
                    next: (response) => {
                        this.reset()
                        this.showSuccessMessage()
                        this.claimView.emit(response)
                    },
                    error: (error) => {
                        this.errorOccurred = true
                        this.reset()
                    }
                });
        }
    }

    toggleVisibility(visibilityScope: VisibilityScopeEnum) {
        this.factToClaim.claimVisibilityScope = visibilityScope
    }

    reset() {
        this.loading = false
        this.publicClaim = false
        this.isTextValue = false
        this.isImageValue = false
        this.claimTypes = []
        this.selectedClaimTypeString = this.defaultClaimMessage
        this.selectedClaimScopeString = this.defaultScopeMessage
        this.scopeSelected = false
        this.selectedClaimType = undefined
        this.selectedClaimScope = undefined
        this.factToClaim = {claimVisibilityScope: VisibilityScopeEnum.Protected, factAspects: [], claimAspects: []}
        this.claimCreationAccepted = false
        this.resetInput();
    }

    fileChangeEvent(event: any): void {
        const files = event.target.files as FileList;
        if (files.length > 0) {
            const file = files[0]
            this.filePath = URL.createObjectURL(file);
            this.fileName = file.name
            this.convertFile(file).subscribe(base64 => {
                this.factToClaim.factValue = base64
            });
            this.resetInput();
        }
    }

    convertFile(file: File): Observable<string> {
        const result = new ReplaySubject<string>(1);
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (event) => result.next(btoa(event!.target!.result!.toString()));
        return result;
    }

    resetInput() {
        const input = document.getElementById('input-image') as HTMLInputElement;
        if (input) {
            input.value = "";
        }
    }

    resolveClaimTypeField($event: string) {
        this.isImageValue = false
        switch ($event) {
            case "passport":
            case "identityCard":
            case "driversLicense":
            case "avatar":
            case "certificate":
            case "certificateOfConduct":
            case "diploma":
            case "degree":
            case "reference":
                this.isImageValue = true
                break;
        }
    }


    resetError() {
        this.errorOccurred = false
    }

    showSuccessMessage() {
        this.claimMadeSuccessfully = true
        setTimeout(() => {
            this.claimMadeSuccessfully = false
        }, 5000);
    }

    setScope(event: any) {
        const scope = event.target.value;
        this.claimQueryControllerService.getClaimMetadata(scope)
            .subscribe({
                next: (response) => {
                    this.claimTypes = response
                    this.selectedClaimScope = event
                    this.selectedClaimScopeString = event.toString()
                    this.scopeSelected = true
                }
            });
    }

    setClaimType(event: any) {
        const claimType = event.target.value
        this.resolveClaimTypeField(claimType)
        this.selectedClaimType = claimType
        this.selectedClaimTypeString = claimType.toString()
    }

    updateDescription($event: any) {
        this.factToClaim.claimDescription = $event.target.value
    }




}
