import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {provideZxvbnServiceForPSM, ZxvbnConfigType} from "angular-password-strength-meter/zxcvbn";
import {translations} from '@zxcvbn-ts/language-en';
import {DataService} from "../../../doatoa-common/services/data.service";
import {
    RequestAuthorizationInterceptor
} from "../../../doatoa-common/services/request-authorization-interceptor.service";
import {CacheInterceptor} from "../../../doatoa-common/services/cache-interceptor";
import {TextFieldModule} from "@angular/cdk/text-field";
import {ResponseInterceptor} from "../../../doatoa-common/services/response-interceptor";
import {CloseAccountModule} from "./modules/close-account/close-account.module";
import {ErrorModule} from "./modules/error/error.module";
import {FactsModule} from "./modules/facts/facts.module";
import {LandingModule} from "./modules/landing/landing.module";
import {SettingsModule} from "./modules/settings/settings.module";
import {MyIdentityModule} from "./modules/my-identity/my-identity.module";
import {IdentitiesModule} from "./modules/identities/identities.module";
import {CommonDoaToaModule} from "../../../doatoa-common/common-doatoa.module";
import {DevelopersModule} from "./modules/developers/developers.module";
import {AppRoutingModule} from "./app-routing/app-routing.module";
import {InfiniteScrollModule} from "ngx-infinite-scroll";


const zxvbnConfig: ZxvbnConfigType = {
    translations: translations,
};

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        CloseAccountModule,
        ErrorModule,
        FactsModule,
        LandingModule,
        AppRoutingModule,
        SettingsModule,
        MyIdentityModule,
        IdentitiesModule,
        DevelopersModule,
        TextFieldModule,
        InfiniteScrollModule,
        CommonDoaToaModule
    ],
    providers: [
        CacheInterceptor, {
        provide: HTTP_INTERCEPTORS,
        useClass: CacheInterceptor,
        multi: true
    }, RequestAuthorizationInterceptor, {
        provide: HTTP_INTERCEPTORS,
        useClass: RequestAuthorizationInterceptor,
        multi: true
    }, ResponseInterceptor, {
        provide: HTTP_INTERCEPTORS,
        useClass: ResponseInterceptor,
        multi: true
    }, HttpClientModule, provideZxvbnServiceForPSM(zxvbnConfig), DataService],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
