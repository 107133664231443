import {Component, Input} from '@angular/core';

import {IdentityView} from "../../../../../../doatoa-common/services";

@Component({
    selector: 'app-developers',
    templateUrl: './developers.component.html',
    styleUrl: './developers.component.scss'
})
export class DevelopersComponent {

    loading: boolean = false
    errorOccurred: boolean = false
    errorMessage: string = ''
}