import {Component, OnInit} from '@angular/core';
import {RedirectService} from "../../../../../../doatoa-common/services/redirect-service";
import {SignOutService} from "../../../../../../doatoa-common/services/signout-service";
import {DataService} from "../../../../../../doatoa-common/services/data.service";
import {
    ClaimReferenceView,
    IdentityCommandControllerService,
    IdentityView, LicenseView, RequestView
} from "../../../../../../doatoa-common/services";
import {HttpErrorResponse} from "@angular/common/http";
import {VisibilityScopeEnum} from "../../../../../../doatoa-common/services/model/visibility-scope";
import {CacheService} from "../../../../../../doatoa-common/services/cache-service";

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

    loading: boolean = false;
    patchLoading: boolean = false
    errorOccurred: boolean = false
    signoutLoading: boolean = false;

    constructor(private cacheService: CacheService, private identityCommandControllerService: IdentityCommandControllerService, private redirectService: RedirectService, private signoutService: SignOutService, protected dataService: DataService) {
    }

    ngOnInit(): void {
    }

    signOut() {
        const idToken = this.dataService.get('idToken')
        if (idToken == null) {
            this.redirectService.redirectToGateway()
        }
        else this.signoutService.signOut(idToken!)
    }

    redirectToChangePasswordPage() {
        const accessToken = this.dataService.get('accessToken')
        this.redirectService.redirectToChangePasswordPage(accessToken!!)
    }

    switch2FA() {
        this.cacheService.bypassCache = true
        this.patchLoading = true
        this.identityCommandControllerService.patchUser([{
            op: this.dataService.get('identity').twoStepAuthenticationEnabled ? "remove" : "add",
            path: "/aspects",
            value: 'two_step_authentication_enabled'
        }]).subscribe({
            next: (response) => {
                const identity = this.dataService.get('identity')
                const patchedIdentity: IdentityView = {
                    username: identity.username,
                    preferredUsername: identity.preferredUsername,
                    avatarId: identity.avatarId,
                    introduction: identity.introduction,
                    visibilityScope: identity.visibilityScope,
                    authorities: identity.authorities,
                    claims: identity.claims,
                    requests: identity.requests,
                    license: identity.license,
                    twoStepAuthenticationEnabled: !identity.twoStepAuthenticationEnabled
                }
                this.patchLoading = false
                this.dataService.setNonDurable('identity', patchedIdentity)
            }
        })
    }
}
