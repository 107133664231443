import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'toBase64ContentType',
})
export class ToBase64ContentTypePipe implements PipeTransform {
    constructor() {
    }

    public transform(value: any, contentType: string): any {
        return `data:${contentType};base64,${value}`;
    }
}