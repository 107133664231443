import {Component, EventEmitter, OnInit, Output} from '@angular/core';

import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {AuthorizationRequestParams} from "../common/services/model/authorization-request-params";
import {AuthorizationRequestView} from "../common/services/model/authorization-request-view";
import {ErrorMessageService} from "../common/services/error-message-service";
import {CryptographyService} from "../common/services/cryptography.service";
import {RedirectService} from "../common/services/redirect-service";
import {OauthControllerService} from "../common/services/api/oauth-controller.service";
import {DataService} from "../common/services/data-storage.service";
import {CryptoWalletService} from "../common/services/crypto-wallet.service";
import {CredentialsPayload} from "../common/services";
import {environment} from "../../environments/environment";
import {LogoComponent} from "../common/logo/logo.component";
import {FormsModule} from "@angular/forms";
import {GenericButtonComponent} from "../common/generic-button/generic-button.component";
import {ErrorModalComponent} from "../common/error-modal/error-modal.component";
import {NgIf, NgOptimizedImage} from "@angular/common";


@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    standalone: true,
    imports: [
        LogoComponent,
        FormsModule,
        GenericButtonComponent,
        ErrorModalComponent,
        NgIf,
        NgOptimizedImage,
        RouterLink
    ],
    styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
    protected email?: string = undefined;
    protected code?: string = undefined;
    protected loading: boolean = false;
    protected twoStepAuthenticationEnabled: boolean = false;
    protected preLoading: boolean = true;
    protected errorOccurred: boolean = false
    protected errorMessage: string = ''
    protected method: string = ''
    protected queryParams?: AuthorizationRequestParams = undefined
    protected authorizationRequest?: AuthorizationRequestView = undefined
    @Output() hideMenu: EventEmitter<boolean> = new EventEmitter<boolean>()

    constructor(private errorMessageService: ErrorMessageService, private cryptographyService: CryptographyService, private redirectService: RedirectService, private oauthControllerService: OauthControllerService, private activatedRoute: ActivatedRoute, private router: Router, private dataService: DataService, private cryptoWalletService: CryptoWalletService) {
    }

    ngOnInit(): void {
        this.hideMenu.emit(true)
        this.activatedRoute.queryParamMap.subscribe((p: any) => {
            this.queryParams = p.params
            if (this.queryParams?.client_id == undefined) {
                //if no client_id is in place we assume people want to sign-in into DoaToa itself, so we populate all query parameters through this redirect
                this.redirectService.redirectToSignInPage()
            } else {
                this.preLoading = false
                setTimeout(() => {
                    this.redirectService.redirectToGateway()
                }, 240000);
            }
        });
    }

    protected authenticate() {
        this.loading = true
        this.dataService.publish('email', this.email)
        let credentialsPayload: CredentialsPayload = {emailAddress: this.email}
        if (this.authorizationRequest == undefined) {
            this.oauthControllerService.initiateOAuthAuthorizationCodeFlow(this.queryParams)
                .subscribe({
                    next: (response) => {
                        this.authorizationRequest = response
                        this.oauthControllerService.requestChallenge(credentialsPayload, this.queryParams)
                            .subscribe({
                                next: async (response) => {
                                    this.dataService.publish('did', response.did)
                                    const challenge = response.challenge
                                    //todo: change into requestLogin(params), see https://docs.trezor.io/trezor-suite/packages/connect/methods/requestLogin.html
                                    const signature = await this.cryptoWalletService.signData(challenge!, this.method);
                                    if (signature.success) {
                                        this.startSignInProcess(signature.payload.signature)
                                    } else {
                                        this.errorOccurred = true;
                                        this.errorMessageService.setMessage("Failed to get a signature.")
                                    }
                                },
                                error: (error) => {
                                    this.errorOccurred = true
                                    setTimeout(() => {
                                        if (this.queryParams?.client_id == environment.webappClientId) {
                                            this.redirectService.redirectToSignInPage();
                                        } else window.location.href = this.authorizationRequest!!.redirectUri!!;
                                    }, 5000);
                                }
                            });
                    },
                    error: (error) => {
                        this.errorOccurred = true
                        setTimeout(() => {
                            this.redirectService.redirectToSignInPage()
                        }, 5000);
                    }
                });
        }

    }

    private startSignInProcess(signature?: string) {
        if (signature) {
            this.oauthControllerService.signIn(this.queryParams, signature)
                .subscribe({
                    next: (response_2) => {
                        this.continueAuthorizationRequest();
                    },
                    error: (error_1) => {
                        this.errorOccurred = true;
                        setTimeout(() => {
                            if (this.queryParams?.client_id == environment.webappClientId) {
                                this.redirectService.redirectToSignInPage();
                            } else window.location.href = this.authorizationRequest!!.redirectUri!!;
                        }, 5000);
                    }
                });
        } else {
            if (this.queryParams?.client_id == environment.webappClientId) {
                this.redirectService.redirectToSignInPage();
            } else window.location.href = this.authorizationRequest!!.redirectUri!!;
        }
    }

    private continueAuthorizationRequest() {
        if (this.authorizationRequest?.approved) {
            this.oauthControllerService.process(true, this.authorizationRequest?.state!)
                .subscribe({
                    next: (response) => {
                        window.location.href = decodeURIComponent(response!.redirectUri!)
                    },
                    error: (error) => {
                        this.errorOccurred = true
                        setTimeout(() => {
                            this.redirectService.redirectToSignInPage()
                        }, 5000);
                    }
                });
        } else {
            this.router.navigate(['/consent'], {
                queryParams: {
                    state: this.authorizationRequest?.state,
                    scopes: this.authorizationRequest?.openIdScopes,
                    client_name: this.authorizationRequest?.client?.name
                }
            })
        }
    }
}
