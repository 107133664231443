import {Component, Input, OnInit} from '@angular/core';
import {IdentityView, LicenseDetailsView, PaymentRequestView} from "../../../../../../doatoa-common/services";
import {
    LicenseCommandControllerService
} from "../../../../../../doatoa-common/services/api/license-command-controller.service";
import {
    LicenseQueryControllerService
} from "../../../../../../doatoa-common/services/api/license-query-controller.service";
import {Router} from "@angular/router";
import {HttpErrorResponse} from "@angular/common/http";
import LicenseNameEnum = LicenseDetailsView.LicenseNameEnum;
import StatusEnum = PaymentRequestView.StatusEnum;
import {
    PaymentCommandControllerService
} from "../../../../../../doatoa-common/services/api/payment-command-controller-service";
import {DataService} from "../../../../../../doatoa-common/services/data.service";

@Component({
    selector: 'app-license',
    templateUrl: './license.component.html',
    styleUrls: ['./license.component.scss']
})
export class LicenseComponent implements OnInit {

    username: string = ''
    loadingPaymentIntent: boolean = false
    termsAndConditionsAccepted: boolean = false
    usernameToChangeLicenseFor: string = ''
    licenseDetailsViews: Array<LicenseDetailsView> = []
    viewedLicense: LicenseDetailsView = {}
    loading: boolean = false
    licenseSuccessfullyChanged: boolean = false
    loadingLicenseDetails: boolean = false
    errorMessage: string = ''
    licenseName?: LicenseNameEnum = undefined
    errorOccurred: boolean = false

    constructor(private licenseCommandControllerService: LicenseCommandControllerService,
                private paymentCommandControllerService: PaymentCommandControllerService,
                protected dataService: DataService,
                private licenseQueryControllerService: LicenseQueryControllerService) {
    }

    ngOnInit(): void {
        if (this.licenseDetailsViews.length == 0) {
            this.getLicenses()
        }
    }

    getLicense(licenseName: LicenseNameEnum) {
        this.loadingLicenseDetails = true
        this.licenseQueryControllerService.getLicense(licenseName)
            .subscribe({
                next: (response) => {
                    this.viewedLicense = response
                    this.loadingLicenseDetails = false
                },
                error: (error) => {
                    this.loadingLicenseDetails = false
                }
            });
    }

    getLicenses() {
        this.licenseQueryControllerService.getLicenses()
            .subscribe({
                next: (response) => {
                    response?.forEach(item => {
                        this.licenseDetailsViews.push(item)
                    })
                    this.loadingLicenseDetails = false
                },
                error: (error) => {
                    this.loadingLicenseDetails = false
                    this.errorOccurred = true
                }
            });
    }

    changeLicense(licenseName: LicenseNameEnum) {
        if (this.licenseName == licenseName)
            this.licenseName = undefined
        else this.licenseName = licenseName
    }

    buyLicense() {
        this.loading = true
        this.licenseCommandControllerService.changeLicense(this.licenseName)
            .subscribe({
                next: (response) => {
                    this.loading = false
                    this.dataService.setNonDurable('license', this.licenseName!!)
                    this.licenseName = undefined
                    this.licenseDetailsViews = []
                    this.termsAndConditionsAccepted = false
                    this.licenseSuccessfullyChanged = true
                },
                error: (error) => {
                    this.loading = false
                    if (error instanceof HttpErrorResponse) {
                        this.errorOccurred = true
                    }
                }
            });
    }

    hasOpenPaymentRequests() {
        const identity = this.dataService.get('identity') as IdentityView
        return identity.license?.paymentRequests?.some((x, y) => x.status == StatusEnum.open);
    }

    protected readonly StatusEnum = StatusEnum;

    switchTermsAndConditionsAccepted() {
        this.termsAndConditionsAccepted = !this.termsAndConditionsAccepted
    }

    initiatePaymentIntent(id: string) {
        this.loadingPaymentIntent = true
        this.paymentCommandControllerService.initiatePaymentIntent(id)
            .subscribe({
                next: (response) => {
                    window.location.href = response.redirectUri
                    this.loadingPaymentIntent = false
                }
            });
    }

    noPaymentHasBeenMade() {
        const identity = this.dataService.get('identity') as IdentityView
        return identity?.license?.paymentRequests?.every((x) => x.paidOn == null)
    }
}
