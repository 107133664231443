import {Component, OnInit} from '@angular/core';
import {GenericButtonComponent} from "../../common/generic-button/generic-button.component";
import {NgForOf, NgIf} from "@angular/common";
import {LogoComponent} from "../../common/logo/logo.component";
import {ErrorMessageService} from "../../common/services/error-message-service";
import {DataService} from "../../common/services/data-storage.service";
import {IdentityControllerService, IdentityPayload, LocaleEnum} from "../../common/services";
import {CryptoWalletService} from "../../common/services/crypto-wallet.service";
import {Router} from "@angular/router";
import TrezorConnect, {Success} from "@trezor/connect-web";
import {SigningResult} from "../../common/services/model/signing-result";
import {DIDDocument, DIDDocumentPayload} from "../../common/services/model/did-document";
import {environment} from "../../../environments/environment";


@Component({
    selector: 'app-mnemonic-phrase',
    standalone: true,
    imports: [
        GenericButtonComponent,
        NgForOf,
        NgIf,
        LogoComponent
    ],
    templateUrl: './mnemonic-phrase.component.html',
    styleUrl: './mnemonic-phrase.component.scss'
})
export class MnemonicPhraseComponent implements OnInit {

    protected termsAndConditionsAccepted: boolean = false
    protected email?: string = undefined;
    protected loading: boolean = false;
    protected noEmail: boolean = false;
    protected errorOccurred: boolean = false
    protected hide = true;
    protected mnemonic: string = '';
    protected mnemonicParts: Array<string> = [];
    protected showCopyConfirmation: boolean = false;

    constructor(private errorMessageService: ErrorMessageService, private dataService: DataService, private identityControllerService: IdentityControllerService, private cryptoWalletService: CryptoWalletService, private router: Router) {
    }

    ngOnInit(): void {
        this.dataService.last<string>('mnemonic').subscribe({
            next: mnemonic => {
                this.mnemonic = mnemonic;
                this.mnemonicParts = mnemonic.split(" ")
            }
        })

    }

    copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(this.mnemonic);
            this.showCopyConfirmation = true
            setTimeout(() => {
                this.showCopyConfirmation = false
            }, 1000);
        } catch (error) {
            console.error("Failed to copy to clipboard:", error);
        }
    };

    switchTermsAndConditionsAccepted() {
        this.termsAndConditionsAccepted = !this.termsAndConditionsAccepted
    }

    async createIdentity() {
        this.loading = true
        this.dataService.last<string>('authenticationMethod').subscribe({
            next: async authenticationMethod => {
                this.dataService.last<string>('email').subscribe({
                    next: async email => {
                        let signature: Success<SigningResult>
                        let didDocumentPayload: DIDDocumentPayload
                        const publicKey: string = await this.resolvePublicKey(authenticationMethod)
                        const did: string = `did:key:${publicKey!}`
                        this.dataService.publish('did', did, true)
                        didDocumentPayload = this.createDIDDocumentPayload(did, publicKey!)
                        const signatureResult = await this.cryptoWalletService.signData(JSON.stringify(didDocumentPayload), authenticationMethod)
                        if (signatureResult.success) {
                            signature = signatureResult
                        } else {
                            this.errorOccurred = true;
                            this.errorMessageService.setMessage("Failed to get a signature.")
                        }
                        const didDocument: DIDDocument = this.createDIDDocument(didDocumentPayload!, signature!.payload.signature)
                        let identityPayload: IdentityPayload = {
                            emailAddress: email,
                            language: LocaleEnum.EN_NL,
                            did: did!,
                            didDocument: didDocument,
                        }
                        this.identityControllerService.register(identityPayload)
                            .subscribe({
                                next: (response) => {
                                    this.router.navigate(['sign-up/payment'])
                                    this.loading = false
                                    this.email == undefined;
                                    this.noEmail = this.email == undefined;
                                },
                                error: (error) => {
                                    this.errorOccurred = true
                                    this.loading = false
                                    this.email == undefined;
                                    this.noEmail = this.email == undefined;
                                }
                            });
                    }
                })
            }
        })

    }

    private createDIDDocumentPayload(did: string, publicKey: string): DIDDocumentPayload {
        return {
            context: "https://www.w3.org/ns/did/v1",
            assertionMethod: [did],
            authentication: [did + '#keys-1'],
            id: did,
            service: [],
            verificationMethod: [
                {
                    id: did + '#keys-1',
                    type: 'Ed25519VerificationKey2018',
                    controller: did,
                    publicKey: publicKey,
                }
            ]
        }
    }

    private createDIDDocument(didDocumentPayload: DIDDocumentPayload, signature: string): DIDDocument {
        return {
            context: didDocumentPayload.context,
            id: didDocumentPayload.id,
            controller: didDocumentPayload.id,
            verificationMethod: [{
                id: didDocumentPayload.verificationMethod[0].id,
                type: didDocumentPayload.verificationMethod[0].type,
                controller: didDocumentPayload.verificationMethod[0].controller,
                publicKey: didDocumentPayload.verificationMethod[0].publicKey
            }],
            authentication: didDocumentPayload.authentication,
            proof: {
                type: didDocumentPayload.verificationMethod[0].type,
                proofPurpose: 'assertionMethod',
                created: new Date().toISOString(),
                verificationMethod: didDocumentPayload.verificationMethod[0].id,
                signatureValue: signature
            }
        }
    }

    private async resolvePublicKey(method: string): Promise<string> {
        switch (method) {
            case 'trezor': {
                const publicKeyRetrievalResponse = await TrezorConnect.getPublicKey({
                    path: environment.cryptoPath
                })
                if (publicKeyRetrievalResponse.success) {
                    return publicKeyRetrievalResponse.payload.publicKey
                }
                throw new Error(publicKeyRetrievalResponse.payload.error)
            }
            case 'doatoa' :
                return this.dataService.getSigningPublicKey()
            default:
                throw new Error(`Unknown method: ${method}`)
        }
    }
}
