import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {
    ClaimCommandControllerService,
    ClaimQueryControllerService,
    ClaimView,
    FactClaimPayload,
    FactQueryControllerService,
    FactView
} from "../../../../../doatoa-common/services";
import {Router} from "@angular/router";
import {HttpErrorResponse} from "@angular/common/http";
import {CacheService} from "../../../../../doatoa-common/services/cache-service";
import {DataService} from "../../../../../doatoa-common/services/data.service";

@Component({
    selector: 'app-facts',
    templateUrl: './facts.component.html',
    styleUrls: ['./facts.component.scss']
})
export class FactsComponent implements OnInit {

    query: string = ''
    loading: boolean = false
    claimingFact: Map<String, boolean> = new Map<String, boolean>()
    @Output() publicFactsLoading: EventEmitter<boolean> = new EventEmitter<boolean>()
    facts: FactView[] = []
    claimTypes: Array<string> = []
    factToClaim: FactClaimPayload = {claimAspects: [], factAspects: [], claimVisibilityScope: 'protected'}
    errorOccurred: boolean = false
    errorMessage: string = ''


    constructor(private dataService: DataService, private cacheService: CacheService, private factQueryControllerService: FactQueryControllerService, private claimQueryControllerService: ClaimQueryControllerService, private claimCommandControllerService: ClaimCommandControllerService, private router: Router) {
    }

    ngOnInit(): void {
        this.loadClaimTypes();
    }

    loadFacts() {
        let tmpQuery = this.query || ''
        this.query = ''
        this.loading = true
        this.publicFactsLoading.emit(this.loading)
        this.cacheService.bypassCache = true
        this.factQueryControllerService.getFacts(tmpQuery)
            .subscribe({
                next: (response) => {
                    this.facts = response
                    this.loading = false
                    this.publicFactsLoading.emit(this.loading)
                },
                error: (error) => {
                    this.loading = false
                    this.publicFactsLoading.emit(this.loading)
                    if (error instanceof HttpErrorResponse) {
                        this.errorOccurred = true
                    }

                }
            });
    }

    claimFact() {
        this.loading = true
        this.claimingFact.set(this.factToClaim.factId!, true)
        this.publicFactsLoading.emit(this.loading)
        this.claimCommandControllerService.makeClaim(this.dataService.get('username'), this.factToClaim)
            .subscribe({
                next: (response) => {
                    this.facts.forEach((element, index) => {
                        if (this.facts[index].id == this.factToClaim.factId) {
                            this.facts.splice(index, 1);
                        }
                    });
                    this.loading = false
                    this.claimingFact.set(this.factToClaim.factId!, false)
                    this.publicFactsLoading.emit(this.loading)
                    this.factToClaim = {claimAspects: [], claimVisibilityScope: 'protected'}
                    // this.loadFacts();
                },
                error: (error) => {
                    this.loading = false
                    this.publicFactsLoading.emit(this.loading)
                    if (error instanceof HttpErrorResponse) {
                        this.errorOccurred = true
                    }
                }
            });
    }

    loadClaimTypes(): void {
        this.claimQueryControllerService.getClaimMetadata("profile")
            .subscribe({
                next: (response) => {
                    this.claimTypes = response
                },
                error: (error) => {
                    if (error instanceof HttpErrorResponse) {
                        this.errorOccurred = true
                    }
                }
            });
    }

    setFactToClaim(factId: string, event: any) {
        this.factToClaim.claimType = event.target.value as string
        this.factToClaim.factId = factId
        this.claimFact()
    }

    search() {
        this.loadFacts()
    }

}
