import {NgModule} from '@angular/core';


import {OauthLandingComponent} from "./oauth-landing/oauth-landing.component";
import {PaymentsUpdateLandingComponent} from "./payments-update/payments-update-landing.component";
import {PaymentsInitComponent} from "./payments-init/payments-init.component";
import {SpinnerComponent} from "../common/spinner/spinner.component";
import {LogoComponent} from "../common/logo/logo.component";
import {NgIf} from "@angular/common";
import {ErrorModalComponent} from "../common/error-modal/error-modal.component";
import {GenericMessageComponent} from "../common/generic-message/generic-message.component";


@NgModule({
    imports: [
        SpinnerComponent,
        LogoComponent,
        NgIf,
        ErrorModalComponent,
        GenericMessageComponent
    ],
    declarations: [OauthLandingComponent, PaymentsUpdateLandingComponent, PaymentsInitComponent]
})
export class LandingModule { }