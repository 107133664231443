<div>
    <div *ngIf="type != ''">
        <button class="btn rounded-circle position-relative width-40 height-40 {{btnClass}} {{disabled ? 'disabled' : ''}}"
                [attr.type]="type">
            <div *ngIf="!loading && !inert"
                 class="position-absolute top-50 start-50 translate-middle"
                 style="z-index: 1;">
                <i *ngIf="showPrimary" class="{{ primaryIconClass }}"></i>
                <i *ngIf="showSecondary" class="{{ secondaryIconClass }}"></i>
            </div>
            <div *ngIf="loading || inert">
                <app-spinner class="position-absolute top-50 start-50 translate-middle pt-1"
                             [spinnerClasses]="'position-absolute top-50 start-50 translate-middle'"
                             [loading]="loading"></app-spinner>
            </div>
        </button>
    </div>
    <div *ngIf="type == ''">
        <div class="btn rounded-circle position-relative width-40 height-40 {{btnClass}} {{disabled ? 'disabled' : ''}}">
            <div *ngIf="!loading && !inert"
                 class="position-absolute top-50 start-50 translate-middle pt-1"
                 style="z-index: 1;">
                <i *ngIf="showPrimary" class="{{ primaryIconClass }}"></i>
                <i *ngIf="showSecondary" class="{{ secondaryIconClass }}"></i>
            </div>
            <div *ngIf="loading || inert">
                <app-spinner class="position-absolute top-50 start-50 translate-middle"
                             [spinnerClasses]="'position-absolute top-50 start-50 translate-middle'"
                             [loading]="loading"></app-spinner>
            </div>
        </div>
    </div>
</div>