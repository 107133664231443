import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    ClaimView,
    RequestCommandControllerService, Requests,
    RequestView
} from "../services";
import {ErrorMessageService} from "../services/error-message-service";
import {DataService} from "../services/data.service";

@Component({
    selector: 'app-new-request-modal',
    templateUrl: './new-request-modal.component.html',
    styleUrls: ['./new-request-modal.component.scss']
})
export class NewRequestModalComponent implements OnInit {

    @Output() submittedRequest: EventEmitter<RequestView> = new EventEmitter<RequestView>()
    @Input() appClaimClass: string = ''
    @Input() title: string = ''
    @Input() customId?: string;
    @Input() subject?: string;
    @Input() subscriptionRequest?: RequestView = undefined
    @Input() shareModal: boolean = false
    selectedClaimScope?: string
    errorOccurred: boolean = false
    showMessage: boolean = false
    loading: boolean = false
    success: boolean = false
    value?: string
    message?: string
    requestor?: string
    defaultScopeMessage: string = "Select a scope..."
    selectedClaimScopeString: string = this.defaultScopeMessage
    todayDate: Date = new Date()
    startingMoment?: string;
    duration: number = 1
    durationUnit: Requests.DurationUnitEnum = Requests.DurationUnitEnum.Years
    durations: Requests.DurationUnitEnum[] = [
        Requests.DurationUnitEnum.Hours,
        Requests.DurationUnitEnum.Days,
        Requests.DurationUnitEnum.Months,
        Requests.DurationUnitEnum.Years
    ]
    description: string = ''
    DurationUnitEnum = Requests.DurationUnitEnum;
    protected readonly Date = Date;
    protected readonly parent = parent;


    constructor(protected dataService: DataService, private requestCommandControllerService: RequestCommandControllerService) {
    }

    ngOnInit(): void {
    }

    requestSubscription() {
        this.loading = true
        const requestee = this.subject!
        this.requestCommandControllerService.makeRequest(requestee,
            {
                requestor: this.requestor,
                scopes: [this.selectedClaimScope!],
                startingMoment: this.startingMoment,
                description: this.description,
                duration: this.duration,
                durationUnit: this.durationUnit
            }
        )
            .subscribe({
                next: (response) => {
                    this.loading = false
                    this.message = 'Successfully submitted the request'
                    this.showMessage = true
                    this.submittedRequest.emit(response)
                },
                error: (error) => {
                    this.loading = false
                    this.errorOccurred = true
                }
            });
    }

    setUnit(event: any) {
        this.durationUnit = event.target.value as Requests.DurationUnitEnum
    }

    setScope(event: any) {
        const scope = event.target.value
        this.selectedClaimScope = scope
        this.selectedClaimScopeString = scope.toString()
    }
}
