import {NgModule} from '@angular/core';
import {CommonDoaToaModule} from "../../../../../doatoa-common/common-doatoa.module";
import {AvatarComponent} from "./avatar/avatar.component";
import {MyClaimsComponent} from "./my-claims/my-claims.component";
import {ProfileIntroductionComponent} from "./identity-summary/profile-introduction.component";
import {MyIdentityComponent} from "./my-identity.component";
import {NewClaimModalComponent} from "../../../../../doatoa-common/new-claim-modal/new-claim-modal.component";
import {MyRequestsComponent} from "./my-requests/my-requests.component";


@NgModule({
    imports: [
        CommonDoaToaModule
    ],
    exports: [
        NewClaimModalComponent
    ],
    declarations: [
        AvatarComponent,
        MyClaimsComponent,
        ProfileIntroductionComponent,
        MyIdentityComponent,
        MyRequestsComponent
    ]
})
export class MyIdentityModule {
}