import {NgModule} from '@angular/core';
import {CommonDoaToaModule} from "../../../../../doatoa-common/common-doatoa.module";
import {CloseAccountComponent} from "./close-account.component";

@NgModule({
    imports: [
        CommonDoaToaModule
    ],
    declarations: [CloseAccountComponent]
})
export class CloseAccountModule { }