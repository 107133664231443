import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {NgIf} from "@angular/common";

@Component({
  selector: 'lib-generic-message',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './generic-message.component.html',
  styleUrl: './generic-message.component.css'
})
export class GenericMessageComponent implements OnChanges, OnDestroy {

  @Input() show: boolean = false;
  @Input() showPlaceholder: boolean = false;
  @Input() message: string = '';
  @Input() placeHolder: string = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['show'].currentValue as boolean) {
      setTimeout(() => {
        this.show = false
      }, 5000);
    }
  }

  ngOnDestroy(): void {
    this.show = false;
  }
}
