import {Component, Input, OnInit} from '@angular/core';
import {saveAs} from "file-saver";
import {HttpErrorResponse} from "@angular/common/http";
import {ClaimQueryControllerService} from "../services";
import {RedirectService} from "../services/redirect-service";
import {DataService} from "../services/data.service";

@Component({
    selector: 'app-export-modal',
    templateUrl: './export-modal.component.html',
    styleUrl: './export-modal.component.scss'
})
export class ExportModalComponent implements OnInit {

    exportLoading: boolean = false
    scope?: string
    @Input() subject?: string;
    @Input() customId?: string
    errorMessage: string = ''
    errorOccurred: boolean = false

    constructor(private claimQueryControllerService: ClaimQueryControllerService, protected dataService: DataService) {
    }

    ngOnInit(): void {
    }

    downloadAsPdf() {
        this.exportLoading = true
        const username = this.subject
        const file_name = `${username!}_${new Date().getTime()}.pdf`
        this.claimQueryControllerService.viewClaims(username!, this.scope)
            .subscribe({
                next: (response) => {
                    saveAs(response as Blob, file_name);
                    this.exportLoading = false
                },
                error: (error) => {
                    this.exportLoading = false
                    if (error instanceof HttpErrorResponse) {
                        this.errorOccurred = true
                    }

                }
            });
    }

    setScopeToDownload(event: any) {
        this.scope = event.target.value as string;
    }


}
