import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Observable, ReplaySubject} from 'rxjs';
import {ImageCroppedEvent, LoadedImage} from "ngx-image-cropper";
import {DomSanitizer} from "@angular/platform-browser";
import {
    ClaimCommandControllerService,
    ClaimQueryControllerService, ClaimView,
    FactQueryControllerService
} from "../../../../../../doatoa-common/services";
import {HttpErrorResponse} from "@angular/common/http";
import {DataService} from "../../../../../../doatoa-common/services/data.service";
import {CacheService} from "../../../../../../doatoa-common/services/cache-service";

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: AvatarComponent
        }
    ]
})
export class AvatarComponent implements OnInit, ControlValueAccessor, OnChanges {
    @Output() updateAvatar: EventEmitter<string> = new EventEmitter<string>();
    errorMessage: string = '';
    filePath: string = '';
    fileName: string = '';
    loading: boolean = false;
    disabled: boolean = false;
    imageChangedEvent: any = '';
    base64UploadedImage: any = '';
    avatarBase64?: string = '';
    errorOccurred: boolean = false
    showEditAvatar: boolean = true;

    constructor(private claimCommandControllerService: ClaimCommandControllerService,
                private claimQueryControllerService: ClaimQueryControllerService,
                private dataService: DataService,
                private cacheService: CacheService,
                private sanitizer: DomSanitizer) {
    }

    ngOnInit(): void {
        this.loadAvatar()
    }

    loadAvatar() {
        if (this.dataService.get('avatar') != undefined) {
            this.loading = true
            this.avatarBase64 = this.dataService.get('avatar')
            if (this.avatarBase64 != undefined) {
                this.base64ToFile();
            }
            this.loading = false
        } else if (this.dataService.get('identity')?.avatarId != undefined) {
            this.loading = true
            this.claimQueryControllerService.getClaim(this.dataService.get('identity').avatarId)
                .subscribe({
                    next: (response) => {
                            this.dataService.setDurable('avatar', response.fact!.value)
                            this.avatarBase64 = response.fact!.value
                            if (this.avatarBase64 != undefined) {
                                this.base64ToFile();
                            }
                        this.loading = false
                    },
                    error: (error) => {
                        this.loading = false
                        if (error instanceof HttpErrorResponse) {
                            this.errorOccurred = true
                        }
                    }
                });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.showEditAvatar = false
        this.avatarBase64 = changes['avatarBase64']?.currentValue
        if (this.avatarBase64 == undefined) {
            this.avatarBase64 = this.dataService.get('avatar') as string
        }
        if (this.avatarBase64 != undefined) {
            this.base64ToFile();
        }
    }

    private base64ToFile() {
        const timestamp = new Date()
        const imageName = 'avatar-' + timestamp.toISOString() + '.jpg';
        const imageBlob = this.dataURItoBlob(this.avatarBase64!!);
        const newFile = new File([imageBlob], imageName, {type: 'image/*'});
        this.fileName = newFile.name
        this.filePath = URL.createObjectURL(newFile);
    }

    dataURItoBlob(dataURI: string) {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        return new Blob([int8Array], {type: 'image/*'});
    }

    writeValue(_file: string): void {
        this.filePath = _file;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    onChange = (fileUrl: string) => {
    };

    onTouched = () => {
    };


    resetInput() {
        const input = document.getElementById('avatar-input-file') as HTMLInputElement;
        if (input) {
            input.value = "";
        }
    }

    fileChangeEvent(event: any): void {
        const files = event.target.files as FileList;
        if (files.length > 0) {
            const file = files[0]
            this.filePath = URL.createObjectURL(file);
            this.fileName = file.name
            this.convertFile(file).subscribe(base64 => {
                this.base64UploadedImage = base64;
            });
            this.resetInput();
        }
    }

    convertFile(file: File): Observable<string> {
        const result = new ReplaySubject<string>(1);
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (event) => result.next(btoa(event!.target!.result!.toString()));
        return result;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.base64UploadedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl!);
        // event.blob can be used to upload the cropped image
        console.log("Image cropped event ")
    }

    imageLoaded(image: LoadedImage) {
        // show cropper
        console.log("Image loaded event ")
    }

    cropperReady() {
        // cropper ready
        console.log("Cropper ready")
    }

    loadImageFailed() {
    }

    uploadPicture() {
        this.loading = true
        this.cacheService.bypassCache = true
        if (!!this.base64UploadedImage) {
            this.claimCommandControllerService.makeClaim(this.dataService.get('username'), {
                claimType: "avatar",
                claimDescription: this.fileName,
                factAspects: [],
                claimAspects: [],
                claimVisibilityScope: 'public',
                factValue: this.base64UploadedImage
            }, undefined)
                .subscribe({
                        next: (response) => {
                            this.dataService.setNonDurable('avatarId', response?.id)
                            this.dataService.setNonDurable('avatar', this.base64UploadedImage)
                            this.updateAvatar.emit(response?.id)
                            this.loadAvatar()
                            this.loading = false
                            this.base64UploadedImage = ''
                        },
                        error: (error) => {
                            this.loading = false
                            if (error instanceof HttpErrorResponse) {
                                this.errorOccurred = true
                            }
                        }
                    }
                )
        }
    }


    arrayBufferToBase64(buffer: ArrayBuffer) {
        let binary = '';
        let bytes = new Uint8Array(buffer);
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

}