<div class="container p-2 mb-4">
    <form (submit)="search()"
          class="form-inline">
        <div class="row justify-content-center mb-3">
            <input [(ngModel)]="query"
                   aria-label="Search"
                   class="col-auto form-control max-width-200 rounded-5 height-40"
                   placeholder="Search"
                   type="search">
            <app-action-button
                    class="col-auto"
                    [useSpinner]="true"
                    [showPrimary]="true"
                    [loading]="loading"
                    [primaryIconClass]="'fa-solid fa-magnifying-glass'"
                    [type]="'submit'">
            </app-action-button>
        </div>
        <div *ngIf="facts == [] || facts?.length === 0"
             class="row justify-content-center greyed-out"
             >No matching facts
        </div>
    </form>
    <div class="p-3 card-group justify-content-center">
        <div *ngFor="let fact of facts">
            <div class="p-2 m-2">
                <form class="text-center">
                    <div class="row secondary-blue card">
                        <app-fact *ngIf="!claimingFact.get(fact.id!!) || false" [fact]="fact"></app-fact>
                        <app-spinner *ngIf="claimingFact.get(fact.id!!) || false" [classList]="'m-2'" [loading]="claimingFact.get(fact.id!!) || false"></app-spinner>
                        <select (change)="setFactToClaim(fact.id!, $event)"
                                [disabled]="loading"
                                class="rounded-5 form-select w-auto m-2 mb-2"
                                id="#"
                                name="type">
                            <option
                                    value="Claim as...">Claim as...
                            </option>
                            <option *ngFor="let claimType of claimTypes"
                                    value="{{claimType}}">{{claimType |  toReadableString}}
                            </option>
                        </select>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
