import {Component, Input} from '@angular/core';
import {ClaimView, RequestView, IdentityView} from "../../../../../../doatoa-common/services";
import {DataService} from "../../../../../../doatoa-common/services/data.service";

@Component({
    selector: 'app-my-requests',
    templateUrl: './my-requests.component.html',
    styleUrl: './my-requests.component.scss'
})
export class MyRequestsComponent {
    loading: Map<string, boolean> = new Map<string, boolean>()
    @Input() loadingRequests: boolean = false
    errorOccurred: boolean = false
    requestToEdit: RequestView = {}
    open: Map<string, boolean> = new Map<string, boolean>()
    showMessage: boolean = false;
    message: string = '';
    
    constructor(protected dataService: DataService) {
    }
    resolveClass(index: number, request: RequestView): string {
        if (request.status == 'pending') {
            return 'btn-basic-notify'
        }
        return index % 2 == 0 ? 'btn-basic-secondary-dark' : 'btn-basic-secondary';
    }

    protected readonly sessionStorage = sessionStorage;

    openOrClose(id: string) {
        this.open.set(id, !this.open.get(id) || false)
    }

    removeRequest($event: RequestView) {
        const identity = this.dataService.get('identity') as IdentityView;
        identity!.requests!.forEach((element, index) => {
            if (identity!.requests![index].id == $event.id) identity!.requests!.splice(index, 1);
            this.message = 'Request deleted!'
            this.showMessage = true
        });
        this.dataService.setNonDurable('identity', identity)
    }

    updateRequest($event: RequestView) {
        const identity = this.dataService.get('identity') as IdentityView;
        identity!.requests!.forEach((element, index) => {
            if (identity!.requests![index].id == $event.id) {
                identity!.requests![index] = $event
            }
            this.message = 'Request updated!'
            this.showMessage = true
        });
        this.dataService.setNonDurable('identity', identity)
    }
}
