import {Component, Input, OnInit} from '@angular/core';
import {IdentityView, LicenseDetailsView} from "../../../../../../doatoa-common/services";
import {
    LicenseQueryControllerService
} from "../../../../../../doatoa-common/services/api/license-query-controller.service";

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrl: './admin.component.scss'
})
export class AdminComponent implements OnInit {
    @Input() userView: IdentityView = {}
    username: string = ''
    licenseDetailsViews: Array<LicenseDetailsView> = []
    viewedLicense: LicenseDetailsView = {}
    loading: boolean = false
    errorMessage: string = ''
    message: string = ''
    errorOccurred: boolean = false
    showMessage: boolean = false

    constructor(private licenseQueryControllerService: LicenseQueryControllerService) {
    }

    ngOnInit(): void {
        this.getLicenses()
    }

    getLicenses() {
        this.licenseQueryControllerService.getLicenses()
            .subscribe({
                next: (response) => {
                    response?.forEach(item => {
                        this.licenseDetailsViews.push(item)
                    })
                    this.loading = false
                },
                error: (error) => {
                    this.loading = false
                    this.errorOccurred = true
                }
            });
    }

    revokeToken() {
        this.loading = true
    }
}
