<div aria-hidden="true"
     aria-labelledby="changeLicenseModal"
     class="modal fade"
     id="changeLicense"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog"
         role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"
                    >Select the license you wish to have</h5>
                <button aria-label="Close"
                        class="btn-close float-end m-1"
                        data-bs-dismiss="modal"
                        type="button">
                </button>
            </div>
            <div class="p-3 justify-content-center">
                <a href="https://doatoa.com/pricing/" class="mx-auto">Which license should I choose?</a>
            </div>
            <div class="modal-body">
                <app-spinner [loading]="loadingLicenseDetails"></app-spinner>
                <div *ngIf="!loadingLicenseDetails">
                    <div *ngFor="let license of licenseDetailsViews">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="ms-5 form-check col-auto w-100 mb-3 {{ dataService.get('identity').license?.details?.name == license.name ? 'disabled' :''}}">
                                    <input (change)="changeLicense(license.name!)"
                                           [checked]="licenseName == license.name"
                                           class="rounded-5 form-check-input"
                                           id="visibilitySwitch"
                                           type="checkbox">
                                    <div>{{ license.name | toLicenseNameWithFrequency }}
                                        <app-license-icon [licenseName]="license.name"></app-license-icon>
                                    </div>
                                    <div >License fee: {{ license.currency }} {{ license.price }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div class="row justify-content-center">
                        <div class="ms-5 form-check col-auto w-100 mb-3">
                            <label for="termsAndConditionsAccepted">I hereby accept this license change and the related <a href="https://doatoa.com/terms-of-service/" target=”_blank”>terms and conditions</a>.</label>
                            <input [checked]="termsAndConditionsAccepted"
                                   (change)="switchTermsAndConditionsAccepted()"
                                   class="rounded-5 form-check-input"
                                   id="termsAndConditionsAccepted"
                                   type="checkbox">
                        </div>
                    </div>
                    <app-generic-button
                            (click)="buyLicense()"
                            [loading]="loading"
                            [icon]="'fa fa-cash-register'"
                            [disabled]="licenseName == undefined || !termsAndConditionsAccepted"
                            [buttonText]="'Buy'">
                    </app-generic-button>
                    <lib-generic-message [show]="licenseSuccessfullyChanged" [message]="'License was changed successfully'"></lib-generic-message>
                </div>
            </div>
            <img class="mx-auto" alt="change-license" ngSrc="assets/img/upgrade.png" height="400" width="400">
        </div>
    </div>
</div>

<div aria-hidden="true"
     aria-labelledby="paymentHistoryModal"
     class="modal fade"
     id="paymentHistory"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog"
         role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"
                    >Payment history</h5>
                <button aria-label="Close"
                        class="btn-close float-end m-1"
                        data-bs-dismiss="modal"
                        type="button">
                </button>
            </div>
            <div class="modal-body">
                <div *ngFor="let payment of dataService.get('identity').license?.paymentRequests">
                    <div class="card p-3 mb-3" *ngIf="payment.paidOn != undefined">
                        <div>Amount: {{ payment.currency }} {{ payment.amount }}</div>
                        <div>Requested on: {{ payment.requestDate | toDateFormat }}</div>
                        <div>Due date: {{ payment.dueDate | toDateFormat }}
                            <span *ngIf="payment.overdue"><i class="fa fa-exclamation-triangle primary-warn ps-3"></i></span>
                        </div>
                        <div>Paid on: {{ payment.paidOn | toDateFormat }}
                            <span *ngIf="payment.status == 'paid'"><i class="fa fa-check primary-blue ps-3"></i></span>
                        </div>
                        <div>For license: {{ payment.licenseName | toLicenseNameWithFrequency }}</div>
                    </div>
                </div>
                <div *ngIf="noPaymentHasBeenMade()"
                     class="greyed-out text-center">
                    You've never made a payment
                </div>
            </div>
        </div>
    </div>
</div>
<div class="card p-3 mb-4">
    <h3 >License</h3>
    <div class="row justify-content-center ">
        <div class="col">License: <span class="secondary-blue">{{ dataService.get('identity').license?.details?.name | toLicenseNameWithFrequency }}</span>
            <app-license-icon [licenseName]="dataService.get('identity').license?.details?.name"></app-license-icon>
        </div>
    </div>
    <div class="row justify-content-center mb-3">
        <div class="col">License fee:
            <span class="secondary-blue"><i class="{{dataService.get('identity').license?.details?.currency | toCurrencySymbol}}"></i>
            {{ dataService.get('identity').license?.details?.price }}</span>
        </div>
    </div>
    <div class="row justify-content-center ">
        <app-generic-button
                class="col m-auto"
                data-bs-target="#changeLicense"
                [attr.data-bs-toggle]="hasOpenPaymentRequests() || false ? '' : 'modal'"
                [loading]="loadingLicenseDetails"
                [disabled]="hasOpenPaymentRequests() || false"
                [buttonText]="'Change your license'"
                [icon]="'fa-solid fa-rotate'">
        </app-generic-button>
    </div>
    <hr>
    <div class="row justify-content-center mb-3">
        <app-generic-button
                class="col m-auto"
                data-bs-target="#paymentHistory"
                data-bs-toggle="modal"
                [loading]="loadingLicenseDetails"
                [buttonText]="'Payment history'"
                [icon]="'fa-solid fa-clock-rotate-left'">
        </app-generic-button>
    </div>
    <div *ngFor="let payment of dataService.get('identity').license?.paymentRequests">
        <div *ngIf="payment.isDue && payment.status != 'paid'" class="card p-3 mb-3">
            <div class="pb-3">
                <div>Amount: <span class="secondary-blue"><i class="fa {{ payment.currency | toCurrencySymbol}}"></i> {{ payment.amount }}</span></div>
                <div>Due date: <span class="secondary-blue">{{ payment.dueDate | toDateFormat }}</span>
                    <i *ngIf="payment.overdue" class="fa fa-exclamation-triangle primary-warn ps-3"></i>
                </div>
                <div>For license: <span class="secondary-blue">{{ payment.licenseName | toLicenseNameWithFrequency }}</span></div>
            </div>
            <app-generic-button (click)="initiatePaymentIntent(payment.id!)"
                                [loading]="loadingPaymentIntent"
                                [disabled]="loadingLicenseDetails"
                                [buttonText]="'To checkout'"
                                [icon]="'fa-solid fa-cart-shopping'"></app-generic-button>
        </div>
    </div>
    <div *ngIf="!hasOpenPaymentRequests()" class="greyed-out text-center">
        No open payment requests
    </div>
</div>
