import {Pipe, PipeTransform} from '@angular/core';
import {ClaimReferenceView} from "../services";
import {VisibilityScopeEnum} from "../services/model/visibility-scope";

@Pipe({
    name: 'toReadableClaimAspect',
})
export class ToReadableClaimAspectPipe implements PipeTransform {
    constructor() {
    }

    public transform(value?: VisibilityScopeEnum): any {
        switch (value) {
            case VisibilityScopeEnum.Public:
                return 'Public (viewable by anyone)'
            case VisibilityScopeEnum.Protected:
                return 'Protected (viewable by non-owners after approval)'
            case VisibilityScopeEnum.Private:
                return 'Private (only viewable by owner)'
            default:
                return ''
        }
    }
}