<div class="container">
    <div *ngIf="!errorOccurred" class="row justify-content-center">
        <app-logo [classes]="'mx-auto d-block mt-2'"
                  [height]="'200'"
                  [image]="'assets/img/doatoa-logo-full.svg'"
                  [width]="'300'"></app-logo>
        <div class="card max-width-400 text-center">
            <div class="p-2">
                <p>{{message}}</p>
                <a href="{{link}}">{{linkText}}</a>
            </div>
        </div>
    </div>
    <app-error-modal [showModal]="errorOccurred"></app-error-modal>
    <lib-generic-message [show]="showMessage" [message]="message"></lib-generic-message>
</div>