import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
    ClaimCommandControllerService,
    ClaimQueryControllerService,
    ClaimReferenceView,
    ClaimView,
    FactView,
    RequestCommandControllerService,
    RequestView,
} from "../services";
import {Router} from "@angular/router";
import {HttpErrorResponse} from "@angular/common/http";
import {VisibilityScopeEnum} from "../services/model/visibility-scope";
import {RedirectService} from "../services/redirect-service";
import {CacheService} from "../services/cache-service";

@Component({
    selector: 'app-claim',
    templateUrl: './claim.component.html',
    styleUrls: ['./claim.component.scss']
})
export class ClaimComponent implements OnInit, OnChanges {
    @Input() claimReference?: ClaimReferenceView
    @Input() claim?: ClaimView
    @Input() myProfile: boolean = false
    @Input() inMyRequestsOverview: boolean = false
    loadingMap: Map<string, boolean> = new Map<string, boolean>()
    @Output() deletedClaimId: EventEmitter<string> = new EventEmitter<string>();
    @Output() deletedRequestId: EventEmitter<string> = new EventEmitter<string>();
    @Output() claimChanged: EventEmitter<string> = new EventEmitter<string>();
    deleteClaimClicked: boolean = false
    errorOccurred: boolean = false
    type: string = ''
    claimToDelete: ClaimReferenceView = {}

    protected readonly VisibilityScopeEnum = VisibilityScopeEnum;
    showCopyConfirmation: boolean = false;

    constructor(private cacheService: CacheService, private claimQueryControllerService: ClaimQueryControllerService, private claimCommandControllerService: ClaimCommandControllerService, private redirectService: RedirectService, private requestCommandControllerService: RequestCommandControllerService, private router: Router) {
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    ngOnInit(): void {
    }

    tryDeleteClaim(claim?: ClaimReferenceView) {
        if (!!claim && !this.claimIsMandatory(claim)) {
            this.deleteClaimClicked = true
            this.claimToDelete = claim
        }
    }

    claimIsMandatory(claim?: ClaimReferenceView) {
        let isMandatory = false;
        for (const aspect of claim?.aspects!) {
            if (aspect == "mandatory") {
                isMandatory = true
            }
        }
        return isMandatory;
    }

    alterVisibility(id: string, visibilityScope: VisibilityScopeEnum) {
        this.loadingMap.set(id + visibilityScope, true)
        this.cacheService.bypassCache = true
        this.claimCommandControllerService.alterClaim(id, [{
            op: "replace",
            path: "/visibilityScope",
            value: visibilityScope.toLowerCase()
        }]).subscribe({
            next: (response) => {
                this.claim!!.visibilityScope = visibilityScope
                this.loadingMap.set(id + visibilityScope, false)
                this.claimReference = response
            },
            error: (error) => {
                this.loadingMap.set(id + visibilityScope, false)
                if (error instanceof HttpErrorResponse) {
                    this.errorOccurred = true
                }
            }
        });
    }

    removeClaim(claimId: string) {
        this.deletedClaimId.emit(claimId)
    }

}
