import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import * as uuid from 'uuid';

import {HttpErrorResponse} from "@angular/common/http";
import {IdentityReferenceView} from "../../../../../../doatoa-common/services/model/identity-reference-view";
import {
    ClaimQueryControllerService,
    ClaimReferenceView,
    ClaimView,
    IdentityQueryControllerService,
    IdentityView,
    LicenseDetailsView,
    RequestView
} from "../../../../../../doatoa-common/services";
import {DataService} from "../../../../../../doatoa-common/services/data.service";

@Component({
    selector: 'app-identity-reference',
    templateUrl: './identity-reference.component.html',
    styleUrls: ['./identity-reference.component.scss']
})
export class IdentityReferenceComponent implements OnInit, OnChanges {

    @Output() reloadCurrentIdentity: EventEmitter<any> = new EventEmitter();
    @Input() scopes: Array<string> = []
    @Input() identityReference: IdentityReferenceView = {}
    @Input() classList: string = '';
    loadingAvatar: boolean = false
    errorOccurred: boolean = false
    identity?: IdentityView
    claimLoading: Map<string, boolean> = new Map<string, boolean>()
    open: Map<string, boolean> = new Map<string, boolean>()
    shortSummary: string = ''
    identityIdentifier?: string = 'my-identity-reference-' + uuid.v4()
    newClaimIdentifier?: string = 'new-claim-' + uuid.v4()
    newRequestIdentifier?: string = 'new-request-' + uuid.v4()
    exportIdentifier?: string =  'export-' + uuid.v4()
    requestedClaim: ClaimReferenceView = {}
    requestedClaims: Map<string, ClaimView> = new Map<string, ClaimView>()
    filePath: string = '';
    fileName: string = '';
    loading: boolean = false;
    imageChangedEvent: any = '';
    base64Avatar: string = '';
    showEditAvatar: boolean = true;
    dataLoaded: boolean = false


    constructor(protected dataService: DataService,
                private claimQueryControllerService: ClaimQueryControllerService,
                private userQueryControllerService: IdentityQueryControllerService) {
    }

    getIdentity() {
        if (this.identity == undefined) {
            this.loading = true
            this.userQueryControllerService.viewIdentity(this.identityReference.username!)
                .subscribe({
                    next: (response) => {
                        this.identity = response
                        this.newClaimIdentifier = 'new-claim-' + this.identity!!.username!!.toLowerCase()
                        this.newRequestIdentifier = 'new-request-' + this.identity!!.username!!.toLowerCase()
                        this.exportIdentifier = 'export-' + this.identity!!.username!!.toLowerCase()
                        this.dataLoaded = true
                        this.loading = false
                    }
                });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.getImage()
        if (this.identityReference?.introduction != undefined && this.identityReference?.introduction!.length > 50) {
            this.shortSummary = this.identityReference?.introduction?.substring(0, 50) + '...'
        } else {
            this.shortSummary = this.identityReference?.introduction || ''
        }

    }

    ngOnInit(): void {
        if (this.base64Avatar == undefined) {
            this.getImage()
        }
    }

    getImage() {
        if (!!this.identityReference?.avatarId) {
            this.loadingAvatar = true
            this.claimQueryControllerService.getClaim(this.identityReference?.avatarId)
                .subscribe({
                    next: (response) => {
                        if (!!response.fact) {
                            this.base64Avatar = response.fact?.value!
                            // this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.base64Avatar);
                            this.base64ToFile()
                            this.loadingAvatar = false
                        }
                    },
                    error: (error) => {
                        if (error instanceof HttpErrorResponse) {
                            this.errorOccurred = true
                        }
                    }
                });

        }
    }

    isLoading(claimId: string): boolean {
        return this.claimLoading.has(claimId) && this.claimLoading.get(claimId)!
    }

    private base64ToFile() {
        const timestamp = new Date()
        const imageName = 'avatar-' + timestamp.toISOString() + '.jpg';
        const imageBlob = this.dataURItoBlob(this.base64Avatar!!);
        const newFile = new File([imageBlob], imageName, {type: 'image/*'});
        this.fileName = newFile.name
        this.filePath = URL.createObjectURL(newFile);
    }


    dataURItoBlob(dataURI: string) {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        return new Blob([int8Array], {type: 'image/jpeg'});
    }

    protected readonly sessionStorage = sessionStorage;
    protected readonly LicenseDetailsView = LicenseDetailsView;
    @Input() index!: number;

    resolveClass() {
        return this.index % 2 == 0 ? 'btn-basic-secondary' : 'btn-basic-secondary-dark';
    }

    resolveSource() {
        return this.index % 2 == 0 ? "../../../../assets/img/person-circle-light.svg" : "../../../../assets/img/person-circle-dark.svg";
    }

    addRequest($event: RequestView) {
        this.identity?.requests?.push($event)
    }

}
