import {Component} from '@angular/core';
import {NgForOf} from "@angular/common";
import {LogoComponent} from "../../common/logo/logo.component";
import {GenericButtonComponent} from "../../common/generic-button/generic-button.component";
import {ToCapitalizedTextPipe} from "../../common/pipes/ToCapitalizedTextPipe";
import {IdentityControllerService} from "../../common/services";
import {environment} from "../../../environments/environment";
import {DataService} from "../../common/services/data-storage.service";

@Component({
    selector: 'app-verification-method',
    standalone: true,
    imports: [
        NgForOf,
        LogoComponent,
        GenericButtonComponent,
        ToCapitalizedTextPipe
    ],
    templateUrl: './verification-method.component.html',
    styleUrl: './verification-method.component.scss'
})
export class VerificationMethodComponent {

    protected chosenVerificationMethod?: string
    protected methods: string[] = environment.verificationMethods

    constructor(private identityControllerService: IdentityControllerService, private dataService: DataService) {
    }

    flipMethod(method: string) {
        if (this.chosenVerificationMethod == method) {
            this.chosenVerificationMethod = undefined
        } else {
            this.chosenVerificationMethod = method
        }
    }

    startProcess() {
        if (this.chosenVerificationMethod != undefined) {
            this.dataService.last<string>('did').subscribe({
                next: did => {
                    this.identityControllerService.startVerification(did, this.chosenVerificationMethod!, ["full_name", "initials", "first_name", "middle_name", "last_name", "date_of_birth"]).subscribe({
                        next: actionView => {
                            if (actionView.action == "redirect") {
                                window.location.href = actionView.payload!
                            }
                        }
                    })
                }
            })

        }
    }
}