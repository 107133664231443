import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {
    ClaimQueryControllerService,
    ClaimReferenceView,
    IdentityQueryControllerService,
    IdentityView,
    RequestView
} from "../../../../../doatoa-common/services";
import {HttpErrorResponse} from "@angular/common/http";
import {SafeResourceUrl} from "@angular/platform-browser";
import * as uuid from "uuid";
import {CacheService} from "../../../../../doatoa-common/services/cache-service";
import {DataService} from "../../../../../doatoa-common/services/data.service";

@Component({
    selector: 'app-my-identity',
    templateUrl: './my-identity.component.html',
    styleUrls: ['./my-identity.component.scss']
})
export class MyIdentityComponent implements OnInit {

    @Output() claimViewEmitter: EventEmitter<ClaimReferenceView> = new EventEmitter<ClaimReferenceView>()
    myProfileId: string = 'my-identity-reference-' + uuid.v4()
    loading: boolean = !this.dataService.has('identity')
    @Output() pageLoading: EventEmitter<boolean> = new EventEmitter<boolean>()
    @Output() myProfileLoading: EventEmitter<boolean> = new EventEmitter<boolean>()
    username?: string = undefined
    queryParams?: any = undefined
    loadingAvatar: boolean = false
    errorOccurred: boolean = false
    errorMessage: string = ''
    flipOpenForRequestsLoading: boolean = false
    imageToUpload?: Promise<ArrayBuffer> = undefined
    imagePath?: SafeResourceUrl = undefined

    constructor(private profileQueryControllerService: IdentityQueryControllerService,
                private claimQueryControllerService: ClaimQueryControllerService,
                protected dataService: DataService,
                private cacheService: CacheService) {
    }

    ngOnInit() {
        this.username = this.dataService.get('username') as string
        this.pageLoading.emit(false)
        if (!this.dataService.has('identity')) {
            this.getIdentity()
        }
        if (!this.dataService.has('scopes')) {
            this.getScopes()
            this.getClaimTypes()
        }
    }

    getIdentity(overrideCache: boolean = false) {
        if (overrideCache) {
            this.cacheService.bypassCache = true
        }
        this.myProfileLoading.emit(this.loading)
        this.profileQueryControllerService.viewIdentity(this.username!)
            .subscribe({
                next: (response) => {
                    this.dataService.setNonDurable('identity', response)
                    this.dataService.setNonDurable('license', response.license?.details?.name!!)
                    this.loading = false
                    this.myProfileLoading.emit(this.loading)
                },
                error: (error) => {
                    this.loading = false
                    this.myProfileLoading.emit(this.loading)
                    if (error instanceof HttpErrorResponse) {
                        this.errorOccurred = true
                    }
                }
            });
    }

    removeRequest($event: RequestView) {
        const currentIdentity = this.dataService.get('identity') as IdentityView
        currentIdentity?.requests!.forEach((element, index) => {
            if (currentIdentity?.requests![index].id == $event.id) {
                currentIdentity?.requests!.splice(index, 1);
            }
        });
        this.dataService.setNonDurable('identity', currentIdentity)
    }

    updateRequest($event: RequestView) {
        const currentIdentity = this.dataService.get('identity') as IdentityView
        currentIdentity?.requests!.forEach((element, index) => {
            if (currentIdentity?.requests![index].id == $event.id) {
                currentIdentity!.requests![index] = $event
            }
        });
        this.dataService.setNonDurable('identity', currentIdentity)
    }

    updateMyClaims(claimView: ClaimReferenceView) {
        const currentIdentity = this.dataService.get('identity') as IdentityView
        currentIdentity?.claims?.push(claimView)
        this.dataService.setNonDurable('identity', currentIdentity)
    }

    // updateClaims(updatedClaims: Array<ClaimReferenceView>) {
    //     const currentIdentity = this.dataService.get('identity') as IdentityView
    //     currentIdentity!.claims = updatedClaims
    // }

    private getClaimTypes() {
        this.claimQueryControllerService.getClaimMetadata("profile")
            .subscribe({
                next: (response) => {
                    this.dataService.setNonDurable('claimTypes', response)
                }
            });
    }

    private getScopes(): void {
        this.claimQueryControllerService.getClaimMetadata(undefined, "openid")
            .subscribe({
                next: (response) => {
                    this.dataService.setNonDurable('scopes', response)
                }
            });
        this.claimQueryControllerService.getClaimMetadata(undefined, "aggregates")
            .subscribe({
                next: (response) => {
                    this.dataService.setNonDurable('scopeAggregates', response)
                    this.dataService.setNonDurable('filteredScopeAggregates', response.filter((x,y,z) => x != 'profile'))
                }
            });
    }

    // updateAvatar($event: string) {
    //     const currentIdentity = this.dataService.get('identity') as IdentityView
    //     currentIdentity!.avatarId = $event
    //     this.dataService.setNonDurable('identity', currentIdentity)
    // }

}
