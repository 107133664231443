import {Injectable} from '@angular/core';
import {RedirectService} from "./redirect-service";
import {DataService} from "./data.service";
import {OauthCommandControllerService} from "./api/oauth-command-controller-service";

@Injectable({
    providedIn: 'root'
})
export class SignOutService {

    constructor(private sessionCommandControllerService: OauthCommandControllerService, private redirectService: RedirectService, private sharedService: DataService) {
    }

    signOut(idToken: string): any {
        this.sessionCommandControllerService.signOut(idToken!)
            .subscribe({
                next: (response) => {
                    this.sharedService.clear()
                    const redirectUri = response.redirectUri
                    if (!!redirectUri) {
                        window.location.href = decodeURIComponent(redirectUri)
                    } else {
                        this.redirectService.redirectToGateway()
                    }
                },
                error: (error) => {
                    this.sharedService.clear()
                    this.redirectService.redirectToGateway()
                }
            });
    }

}
