/**
 * DoaToa
 * Manage your data and your digital identities, your data your choice.
 *
 * The version of the OpenAPI document: 1.0.0-alpha
 * Contact: info@compilit.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {DIDDocument} from "./did-document";

export type LocaleEnum = 'NL_NL' | 'EN_US';
export const LocaleEnum = {
    NL_NL: 'NL_NL' as LocaleEnum,
    EN_NL: 'EN_NL' as LocaleEnum,
    EN_US: 'EN_US' as LocaleEnum
};

export interface IdentityPayload {
    did?: string;
    emailAddress?: string;
    didDocument?: DIDDocument;
    password?: string;
    language?: LocaleEnum;
}



