import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ClaimReferenceView, DecisionPayload, DecisionPayloadNs, RequestCommandControllerService, RequestView} from "../services";
import {HttpErrorResponse} from "@angular/common/http";
import DecisionEnum = DecisionPayloadNs.DecisionEnum;

@Component({
    selector: 'app-request',
    templateUrl: './request.component.html',
    styleUrls: ['./request.component.scss']
})
export class RequestComponent implements OnInit {

    loading: boolean = false
    loadingMap: Map<string, boolean> = new Map<string, boolean>()
    reason?: string = undefined
    @Input() requestee: string = ''
    @Input() parent!: ClaimReferenceView;
    @Input() addressedToMe: boolean = false
    @Input() initiatedByMe: boolean = false
    @Input() request!: RequestView
    @Output() requestDeleted: EventEmitter<RequestView> = new EventEmitter<RequestView>()
    @Output() requestUpdated: EventEmitter<RequestView> = new EventEmitter<RequestView>()
    errorOccurred: boolean = false

    constructor(private requestCommandControllerService: RequestCommandControllerService) {
    }

    ngOnInit(): void {
    }

    //todo: move to pipe

    resolveIcon(status: string): string {
        switch (status) {
            case "created":
                return 'fa-solid fa-clock secondary-info'
            case "pending":
                return 'fa-solid fa-question-circle secondary-info'
            case "approved":
                return 'fa fa-check secondary-info'
            case "denied":
                return 'fa-solid fa-circle-xmark primary-danger'
            case "altered":
                return 'fa fa-question-circle secondary-info'
            case "exception":
                return 'fa fa-question-circle primary-danger'
            case "archived":
                return 'fa fa-archive greyed-out'
            default:
                return ''
        }
    }

    processRequest(verdict: DecisionEnum) {
        if (this.requestIsOpen(this.request)) {
            this.loadingMap.set(verdict, true)
            const decisionPayload: DecisionPayload = {
                reason: this.reason,
                decision: verdict
            }
            this.requestCommandControllerService.processRequest(this.request.id!!, decisionPayload)
                .subscribe({
                    next: (response) => {
                        this.loadingMap.set(verdict, false)
                        this.request = response
                        this.requestUpdated.emit(this.request)
                    },
                    error: (error) => {
                        if (error instanceof HttpErrorResponse) {
                            this.errorOccurred = true
                        }
                        this.loading = false
                    }
                });
        }
    }

    requestIsOpen(request: RequestView): boolean {
        switch (request.status) {
            case "none":
                return true
            case "created":
                return true
            case "pending":
                return true
            case "approved":
                return false
            case "denied":
                return false
            case "altered":
                return true
            case "exception":
                return true
            case "archived":
                return false
            default:
                return true
        }
    }

    deleteRequest() {
        this.loadingMap.set('delete', true)
        this.requestCommandControllerService.delete(this.request.id!)
            .subscribe({
                next: (response) => {
                    this.loadingMap.set('delete', false)
                    this.requestDeleted.emit(this.request)
                },
                error: (error) => {
                    this.loading = false
                    if (error instanceof HttpErrorResponse) {
                        this.errorOccurred = true
                    }
                }
            });
    }

    protected readonly RequestView = RequestView;
}