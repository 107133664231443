<div class="container card p-3 mb-5">
    <div class="row">
        <span class="col-lg-auto">{{ request.type | toReadableString }}
            request by <span class="secondary-blue">{{ request.initiator }}</span> on:
            <span *ngFor="let scope of request.scopes" class="secondary-blue">{{ scope | toReadableString }}</span>
        </span>
    </div>
    <div class="row">
        <div class="col-lg-auto"
             >
              <span class="col-lg-auto">Status: <span class="secondary-blue"><i class="{{resolveIcon(request.status!!)}}"></i> ({{ request.status | toReadableRequestStatus }})</span>
        </span>

        </div>
    </div>
    <div class="row mb-3">
        <div class="col-lg-auto"
             >Description:
            <span class="secondary-blue">{{ request.description }}</span>
        </div>
    </div>
    <div class="row mb-3" *ngIf="addressedToMe && requestIsOpen(request)">
        <div class="col"
             >
             <textarea [(ngModel)]="reason"
                       class="form-control rounded-1 w-100"
                       id="decisionReason"
                       name="value"
                       placeholder="Reason for decision (optional)">
                </textarea>
        </div>
    </div>
    <div class="row mb-3" *ngIf="!!request.events && request.events!.length > 0">
        <h5>Last event:</h5>
        <div class="col"
             >
            <app-event [event]="request!.events![request.events!.length - 1]!"></app-event>
        </div>
    </div>
    <div class="row align-items-center justify-content-between">
        <div class="d-flex justify-content-end p-0">
            <app-action-button
                    class="me-2 {{!requestIsOpen(request) || !addressedToMe ? 'd-none':''}}"
                    [useSpinner]="false"
                    (click)="processRequest('approve')"
                    [disabled]="!requestIsOpen(request) || !addressedToMe || (loadingMap.get('approve') || false)"
                    [showPrimary]="requestIsOpen(request) && addressedToMe"
                    [showSecondary]="!requestIsOpen(request) || !addressedToMe"
                    [loading]="loadingMap.get('approve') || false"
                    [btnClass]="'btn-basic-info'"
                    [primaryIconClass]="'fa-solid fa-circle-check'"
                    [secondaryIconClass]="'fa-solid fa-circle-check greyed out'"
            >
            </app-action-button>
            <app-action-button
                    class="me-2 {{!requestIsOpen(request) || !addressedToMe ? 'd-none':''}}"
                    [useSpinner]="false"
                    (click)="processRequest('deny')"
                    [disabled]="!requestIsOpen(request) || !addressedToMe || (loadingMap.get('deny') || false)"
                    [showPrimary]="requestIsOpen(request) && addressedToMe"
                    [showSecondary]="!requestIsOpen(request) || !addressedToMe"
                    [loading]="loadingMap.get('deny') || false"
                    [btnClass]="'btn-primary-danger'"
                    [primaryIconClass]="'fa-solid fa-circle-xmark'"
                    [secondaryIconClass]="'fa-solid fa-circle-xmark greyed-out'"
            >
            </app-action-button>
            <app-action-button *ngIf="initiatedByMe && request!.type! != RequestView.TypeEnum.proposedClaim"
                    class="me-2 {{!initiatedByMe ? 'd-none':''}}"
                    [useSpinner]="false"
                    [disabled]="!initiatedByMe && request.type != 'proposed_claim'"
                    [showPrimary]="initiatedByMe && request.type != 'proposed_claim'"
                    [showSecondary]="!initiatedByMe"
                    [primaryIconClass]="'fa fa-pencil'"
                    [secondaryIconClass]="'fa fa-pencil greyed-out'"
                    data-bs-target="#requestEditModal"
                    [attr.data-bs-toggle]="initiatedByMe || request.type == 'proposed_claim' ? 'modal' : null">
            </app-action-button>
            <app-action-button
                    class="me-2"
                    (click)="deleteRequest()"
                    [disabled]="loadingMap.get('delete') || false"
                    [loading]="loadingMap.get('delete') || false"
                    [showPrimary]="true"
                    [primaryIconClass]="'fa fa-trash'">
            </app-action-button>
            <app-action-button
                    class="me-2"
                    data-bs-target="#requestHistory"
                    data-bs-toggle="modal"
                    [useSpinner]="false"
                    [showPrimary]="true"
                    [primaryIconClass]="'fa fa-clock-rotate-left'">
            </app-action-button>
        </div>
    </div>
</div>
<div aria-hidden="true"
     aria-labelledby="requestHistoryLabel"
     class="modal fade"
     id="requestHistory"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog"
         role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"
                    >Request history</h5>
                <button aria-label="Close"
                        class="btn-close float-end m-1"
                        data-bs-dismiss="modal"
                        type="button">
                </button>
            </div>
            <div class="modal-body">
                <div class="container justify-content-center">
                    <div *ngFor="let event of request?.events; index as i" class="row">
                        <i *ngIf="i != 0" class="mb-1 mt-3 text-center fa-solid fa-2x fa-chevron-down primary-blue"></i>
                        <app-event [event]="event"></app-event>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>