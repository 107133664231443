<div class="card p-3 mb-4">
    <h3 >Admin</h3>
    <div class="row justify-content-center">
        <lib-generic-message
                [message]="message"
                [show]="showMessage"></lib-generic-message>
    </div>
    <hr/>
    <div class="row justify-content-center">
        <form (submit)="revokeToken()">
            <div class="row justify-content-center">
                <div class="col-auto ps-3 pe-3 mb-3 m-auto">
                    <input [(ngModel)]="username"
                           class="rounded-5 height-40 m-auto ps-2"
                           datatype="text"
                           id="username-to-revoke-tokens-of"
                           name="username"
                           placeholder="Enter username"
                           type="text">
                </div>
            </div>
            <div class="row justify-content-center">
                <app-generic-button
                        class="col-auto m-auto w-100"
                        [widthClass]="''"
                        [dataBsDismiss]="'modal'"
                        [type]="'submit'"
                        [disabled]="username == '' || username == undefined"
                        [loading]="loading"
                        [buttonText]="'Revoke refresh token'"
                        [icon]="'fa-solid fa-burst'">
                </app-generic-button>
            </div>
        </form>
    </div>
    <app-error-modal [showModal]="errorOccurred" [message]="errorMessage"></app-error-modal>
</div>