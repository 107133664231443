import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'toDateTimeFormat'
})
export class ToDateTimeFormatPipe implements PipeTransform {

    transform(value: string | undefined, ...args: unknown[]): string {
        const options: Intl.DateTimeFormatOptions = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        };
        if (value == undefined)
            return "";
        const date = new Date(value);
        return new Intl.DateTimeFormat('en-GB', options).format(date);
    }

}
