<div tabindex=-1
     class="modal fade"
     id="deleteModal"
     role="dialog">
    <div class="modal-dialog modal-lg"
         role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"
                    >Please enter the type of the claim ({{ claimToDelete.type || '' }}) to
                    confirm.</h5>
                <button aria-label="Close"
                        class="btn-close float-end m-1"
                        data-bs-dismiss="modal"
                        type="button">
                </button>
            </div>
            <div class="modal-body">
                <input (reset)="deleteClaim()"
                       [(ngModel)]="claimType"
                       class="form-control rounded-5"
                       id="value"
                       name="value"
                       type="text"
                       datatype="text"
                       placeholder="Value">
            </div>
            <div class="modal-footer container-fluid justify-content-center">
                <app-generic-button
                        class="col"
                        [widthClass]="'w-100'"
                        [dataBsDismiss]="'modal'"
                        [type]="'submit'"
                        [buttonText]="'My mistake!'"
                        [btnClass]="'btn-basic-info'">
                </app-generic-button>
                <app-generic-button
                        class="col"
                        (click)="deleteClaim()"
                        [widthClass]="'w-100'"
                        [disabled]="(loading.get(claimToDelete.id!) || false) || !nameMatches()"
                        [loading]="loading.get(claimToDelete.id!) || false"
                        [buttonText]="'Delete claim'"
                        [btnClass]="'btn-primary-danger'"
                        data-bs-dismiss="modal">
                </app-generic-button>
            </div>
        </div>
    </div>
</div>