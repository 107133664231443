import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-payments-landing',
  templateUrl: './payments-update-landing.component.html',
  styleUrl: './payments-update-landing.component.scss'
})
export class PaymentsUpdateLandingComponent implements OnInit {

  message: string = ''
  link: string = ''
  linkText: string = ''
  @Input() loading: boolean = false
  showMessage: boolean = false
  @Input() errorOccurred: boolean = false

  constructor(private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe((p: any) => {
      const status = p.params.status
      if (status == 'processed') {
        this.message = "This payment was already processed"
        this.link = "/#/my-identity"
        this.linkText = "Back to my identity"
      }
      if (status == 'expired') {
        this.message = "This payment was expired"
        this.link = "/#/my-identity"
        this.linkText = "Back to my identity"
      }
      if (status == 'cancelled') {
        this.message = "This payment was cancelled"
        this.link = "/#/my-identity"
        this.linkText = "Back to my identity"
      }
      if (status == 'failed') {
        this.message = "Payment has failed, please try again"
        this.link = "/#/my-identity"
        this.linkText = "Back to my identity"
      }
      if (status == 'success') {
        this.message = "Payment was made successfully"
        this.link = "/#/my-identity"
        this.linkText = "Back to my identity"
      }
      this.showMessage = true
    })
  }

}
